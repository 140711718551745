import ClientWidget from "@/components/Widgets/ClientWidget.vue";
import StudentGroupsWidget from "@/components/Widgets/StudentGroupsWidget.vue";
import LecturesStudentsWidget from "@/components/Widgets/LecturesStudentsWidget.vue";
import TracksStudentsWidget from "@/components/Widgets/TracksStudentsWidget.vue";
import LessonVideosWidget from "@/components/Widgets/LessonVideosWidget.vue";
import LessonExercisesWidget from "@/components/Widgets/LessonExercisesWidget.vue";
import LessonLawsWidget from "@/components/Widgets/LessonLawsWidget.vue";
import LessonVerdictsWidget from "@/components/Widgets/LessonVerdictsWidget.vue";
import LessonBooksWidget from "@/components/Widgets/LessonBooksWidget.vue";
import StudentCoursesWidget from "@/components/Widgets/StudentCoursesWidget.vue";
import GroupLessonsWidget from "@/components/Widgets/GroupLessonsWidget.vue";
import GroupStudentsWidget from "@/components/Widgets/GroupStudentsWidget.vue";
import GroupEscortsWidget from "@/components/Widgets/GroupEscortsWidget.vue";
import GroupExercisesWidget from "@/components/Widgets/GroupExercisesWidget.vue";
import StudentExercisesWidget from "@/components/Widgets/StudentExercisesWidget.vue";
import RequestHistoryWidget from "@/components/Widgets/RequestHistoryWidget.vue";
import RequestAdminsWidget from "@/components/Widgets/RequestAdminsWidget.vue";
import ExpoChapterQuestionsWidget from "@/components/Widgets/ExpoChapterQuestionsWidget.vue";
import LectureUsersWidget from "@/components/Widgets/LectureUsersWidget.vue";
import LectureLearningTrackSubjectsWidget from "@/components/Widgets/LectureLearningTrackSubjectsWidget.vue";
import LectureTrackSubjectLecturesWidget from "@/components/Widgets/LectureTrackSubjectLecturesWidget.vue";

export default {
  ClientWidget,
  StudentGroupsWidget,
  LecturesStudentsWidget,
  TracksStudentsWidget,
  LessonVideosWidget,
  LessonExercisesWidget,
  LessonLawsWidget,
  LessonVerdictsWidget,
  LessonBooksWidget,
  GroupLessonsWidget,
  StudentCoursesWidget,
  GroupStudentsWidget,
  GroupEscortsWidget,
  GroupExercisesWidget,
  StudentExercisesWidget,
  RequestHistoryWidget,
  RequestAdminsWidget,
  ExpoChapterQuestionsWidget,
  LectureUsersWidget,
  LectureLearningTrackSubjectsWidget,
  LectureTrackSubjectLecturesWidget
};
