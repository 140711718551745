import { render, staticRenderFns } from "./LessonLawsWidget.vue?vue&type=template&id=6fbe8d86&scoped=true&"
import script from "./LessonLawsWidget.vue?vue&type=script&lang=js&"
export * from "./LessonLawsWidget.vue?vue&type=script&lang=js&"
import style0 from "./LessonLawsWidget.vue?vue&type=style&index=0&id=6fbe8d86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fbe8d86",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.Simulator\\Nevo.Simulator.Admin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fbe8d86')) {
      api.createRecord('6fbe8d86', component.options)
    } else {
      api.reload('6fbe8d86', component.options)
    }
    module.hot.accept("./LessonLawsWidget.vue?vue&type=template&id=6fbe8d86&scoped=true&", function () {
      api.rerender('6fbe8d86', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Widgets/LessonLawsWidget.vue"
export default component.exports