var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadExpoQuestions()
            }
          }
        },
        [_vm._v(" עריכת מקבץ ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "link-button",
                          on: { click: _vm.editLawCollection }
                        },
                        [_vm._v("שמור")]
                      )
                    ]
                  ),
                  !_vm.isLoading && _vm.model
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v("מקבץ חקיקה " + _vm._s(_vm.lawCollection.name))
                        ]),
                        _c(
                          "div",
                          { staticClass: "LessonEntityDetails__Component" },
                          [
                            _c("FieldInlineText", {
                              staticStyle: { width: "50%" },
                              attrs: { label: "שם המקבץ" },
                              on: { onChange: _vm.onChange },
                              model: {
                                value: _vm.model.name,
                                callback: function($$v) {
                                  _vm.$set(_vm.model, "name", $$v)
                                },
                                expression: "model.name"
                              }
                            }),
                            _c("div", { staticClass: "checkbox-container" }, [
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-switch",
                                    {
                                      staticStyle: { width: "200px" },
                                      on: { input: _vm.onChange },
                                      model: {
                                        value: _vm.model.displaySeperateLawText,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.model,
                                            "displaySeperateLawText",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "model.displaySeperateLawText"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.model.displaySeperateLawText
                                            ? "דרופדאון"
                                            : "ללא דרופדאון"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-switch",
                                    {
                                      staticStyle: { width: "200px" },
                                      on: { input: _vm.onChange },
                                      model: {
                                        value: _vm.model.isAcademy,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "isAcademy", $$v)
                                        },
                                        expression: "model.isAcademy"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.model.isAcademy
                                            ? "אקדמיה"
                                            : "מתמחה"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "b-switch",
                                    {
                                      staticStyle: { width: "200px" },
                                      on: { input: _vm.onChange },
                                      model: {
                                        value: _vm.model.isActive,
                                        callback: function($$v) {
                                          _vm.$set(_vm.model, "isActive", $$v)
                                        },
                                        expression: "model.isActive"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.model.isActive
                                            ? "פעיל"
                                            : "לא פעיל"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _c("FieldInlineSelect", {
                              attrs: {
                                label: "בחר חוק מהרשימה",
                                filterable: true,
                                clearable: true,
                                reduce: function(item) {
                                  return item
                                },
                                optionsAsync: _vm.getLaws
                              },
                              on: { onChange: _vm.searchLawClauses },
                              model: {
                                value: _vm.chosenLaw,
                                callback: function($$v) {
                                  _vm.chosenLaw = $$v
                                },
                                expression: "chosenLaw"
                              }
                            }),
                            _vm.chosenLaw
                              ? _c("div", { staticClass: "new-law" }, [
                                  _c("div", { staticClass: "item" }, [
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.chosenLaw.Text))
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "add",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function($event) {
                                            return _vm.addVerdictItem()
                                          }
                                        }
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { pack: "fa", icon: "plus" }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.clauses.length
                              ? _c(
                                  "div",
                                  { staticClass: "clauses" },
                                  _vm._l(_vm.clauses, function(clause, idx) {
                                    return _c(
                                      "div",
                                      {
                                        key: idx,
                                        staticClass: "clause",
                                        class: [
                                          clause.isChecked ? "clicked" : ""
                                        ],
                                        on: {
                                          click: function($event) {
                                            return _vm.addClauseToLaw(clause)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(clause.name) + " ")]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              { staticClass: "laws-items" },
                              _vm._l(_vm.model.laws, function(lawItem) {
                                return _c("div", { key: lawItem.lawId }, [
                                  _c(
                                    "div",
                                    { staticClass: "law-item" },
                                    [
                                      _c("p", [
                                        _vm._v(_vm._s(lawItem.lawName))
                                      ]),
                                      _c("b-input", {
                                        staticStyle: { width: "70px" },
                                        attrs: { type: "number" },
                                        on: { input: _vm.onChange },
                                        model: {
                                          value: lawItem.sortOrder,
                                          callback: function($$v) {
                                            _vm.$set(lawItem, "sortOrder", $$v)
                                          },
                                          expression: "lawItem.sortOrder"
                                        }
                                      }),
                                      _c("div", { staticClass: "delete" })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "clauses" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "clause",
                                          class: [
                                            lawItem.isFullLaw && "clicked"
                                          ],
                                          on: {
                                            click: function($event) {
                                              ;(lawItem.isFullLaw = !lawItem.isFullLaw),
                                                _vm.onChange()
                                            }
                                          }
                                        },
                                        [_vm._v("חוק מלא")]
                                      ),
                                      _vm._l(lawItem.lawClauses, function(
                                        clause,
                                        idx
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: idx,
                                            staticClass: "clause",
                                            class: {
                                              clicked: clause.isChecked
                                            },
                                            on: {
                                              click: function($event) {
                                                ;(clause.isChecked = !clause.isChecked),
                                                  _vm.onChange()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(clause.name) + " "
                                            )
                                          ]
                                        )
                                      })
                                    ],
                                    2
                                  )
                                ])
                              }),
                              0
                            ),
                            _vm.model.videos && _vm.model.videos.length
                              ? _c(
                                  "b-field",
                                  {
                                    staticStyle: { "margin-top": "15px" },
                                    attrs: { label: "סרטונים מקושרים" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "videos" },
                                      _vm._l(_vm.model.videos, function(video) {
                                        return _c(
                                          "div",
                                          {
                                            key: video.id,
                                            staticClass: "video"
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(video.name) + " "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.model.exercises && _vm.model.exercises.length
                              ? _c(
                                  "b-field",
                                  {
                                    staticStyle: { "margin-top": "15px" },
                                    attrs: { label: "תרגולים מקושרים" }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "exercises" },
                                      _vm._l(_vm.model.exercises, function(
                                        exercise
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: exercise.id,
                                            staticClass: "exercise"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "bold"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(exercise.name) + " "
                                                )
                                              ]
                                            ),
                                            exercise.lessonName
                                              ? _c("span", [
                                                  _vm._v("מיחידת הלימוד "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          exercise.lessonName
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e(),
                                            exercise.courseName
                                              ? _c("span", [
                                                  _vm._v("בקורס "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-weight": "bold"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          exercise.courseName
                                                        ) + " "
                                                      )
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }