var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadUserExercises()
            }
          }
        },
        [_vm._v(" תרגולים ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 2 },
          on: {
            click: function($event) {
              return _vm.loadUserRemarks()
            }
          }
        },
        [_vm._v(" יומן מלווה לתלמיד ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.openSimulator }
                        },
                        [_vm._v("הפניה לסימולטור")]
                      )
                    ]
                  ),
                  !_vm.isLoading && _vm.exercises && _vm.exercises.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v("התרגולים של "),
                          _c("b", [_vm._v(_vm._s(_vm.userName))]),
                          _vm._v(" בקורס " + _vm._s(_vm.course.name))
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(0),
                            _vm._l(_vm.exercises, function(exercise, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.name))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(Math.floor(exercise.grade)))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.acceptableGrade))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("0")(
                                          Math.floor(exercise.starPercent)
                                        )
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        exercise.StarGrade > -1
                                          ? Math.trunc(exercise.StarGrade)
                                          : "---"
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        Math.floor(exercise.grayPercent) || 0
                                      )
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(Math.floor(exercise.grayGrade))
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.dAhozonNum || "---"))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.parseDate(exercise.endDateTime)
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "הדפס פתרון"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "green" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "file" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.getPrintExerciseUrl(
                                            exercise.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "הפדס שאלון"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            { staticClass: "updateItem" },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "file" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "column-item" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: exercise.wasResearched,
                                          expression: "exercise.wasResearched"
                                        }
                                      ],
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(
                                          exercise.wasResearched
                                        )
                                          ? _vm._i(
                                              exercise.wasResearched,
                                              null
                                            ) > -1
                                          : exercise.wasResearched
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.updateExercise(
                                            exercise,
                                            exercise.id
                                          )
                                        },
                                        change: function($event) {
                                          var $$a = exercise.wasResearched,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  exercise,
                                                  "wasResearched",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  exercise,
                                                  "wasResearched",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              exercise,
                                              "wasResearched",
                                              $$c
                                            )
                                          }
                                        }
                                      }
                                    })
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.resetUserExercise(exercise)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "איפוס תרגול"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "red" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "delete" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 2
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "new", on: { click: _vm.newRemark } },
                        [_vm._v("הוספת הערה")]
                      )
                    ]
                  ),
                  !_vm.isLoading &&
                  _vm.studentRemarks &&
                  _vm.studentRemarks.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "יומן מלווה לתלמיד " +
                              _vm._s(_vm.userName) +
                              " בקורס " +
                              _vm._s(_vm.course.name)
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(1),
                            _vm._l(_vm.studentRemarks, function(remark, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(remark.byUserName))
                                  ]),
                                  _c("div", {
                                    staticClass: "column-item",
                                    domProps: {
                                      innerHTML: _vm._s(remark.contentToDisplay)
                                    }
                                  }),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(_vm.parseDate(remark.dateCreated))
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" תרגול ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" טווח תקין ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוז כוכביות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון כוכביות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוז אפורות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" ציון אפורות ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" אחוזון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" פתרון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שאלון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תוחקר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" איפוס ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" על ידי ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" נושא ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }