<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">תלמידים רשומים להרצאה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <v-select style="width: 60%;" dir="rtl" label="Text" v-model="userId" :options="users"
                    :filterable="true" :clearable="true" :reduce="user => user.Value" @search="onSearchUsers" :searchable="true"
                    :close-on-select="true" :placeholder="'חפש תלמיד מהרשימה'">
                    <div slot="no-options">לא נמצא תלמיד</div>
                </v-select>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
    name: "DialogReportDriverAssign",
    props: ["store", "lectureId"],
    components: {
        vSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
    },
    data() {
        return {
            isLoading: false,
            userId: null,
            users: []
        };
    },
    methods: {
        onSearchUsers(search, loading) {
            if (search.length > 3) {
                loading(true);
                return CoursesService.getUsersByCourse(this.store.state.auth.course?.id, {
                    Terms: search,
                    PageSize: 999,
                    IsAscending: false,
                    PageNumber: 1,
                    ResultType: 3
                })
                    .then((r) => {
                        if (!r.data.items.length) {
                            console.log('no!');
                        } else {
                            this.users = r.data.items.map((i) => ({ Value: i.id, Text: `${i.privateName} ${i.lastName} - ${i.userName}` }));
                        }
                    })
                    .finally(() => {
                        loading(false);
                    });
            }

            return null;
        },
        save() {
            this.isLoading = true;
            CoursesService.addLectureStudent(this.lectureId, this.userId, {
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    this.$emit('customEvent');
                    this.$emit("onSave");
                    // window.location.reload();
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm");
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    }
}
</script>

<style scoped lang="scss">
.DialogReportPaid__Component__Loading {
    max-height: 600px;
    overflow: auto;
}
</style>
