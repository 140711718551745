var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", {}, [
        _vm.loading
          ? _c(
              "span",
              { staticClass: "loading" },
              [
                _c("ClipLoader", {
                  attrs: { color: "#2e3f50", loading: _vm.loading, size: 20 }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.groupStudents && _vm.groupStudents.length
          ? _c(
              "div",
              { staticClass: "GroupLesson__Container" },
              _vm._l(_vm.groupStudents, function(student) {
                return _c(
                  "div",
                  {
                    key: student.id,
                    staticClass: "GroupLessonWidget__Component",
                    class: [student.isSelected && "green"]
                  },
                  [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          student.privateName +
                            " " +
                            student.lastName +
                            " - " +
                            student.userName
                        )
                      )
                    ])
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        _vm.groupStudents && _vm.groupStudents.length
          ? _c("div", { staticClass: "link-btn" }, [
              _c("a", { on: { click: _vm.showAll } }, [_vm._v("הצג הכל")])
            ])
          : _vm._e(),
        !_vm.loading && !_vm.groupStudents.length
          ? _c("div", [_vm._v("אין סטודנטים המשויכים לקבוצה זו")])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }