var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("תצוגה מקדימה לשאלה " + _vm._s(_vm.id))
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.model.question) } }),
            _c(
              "div",
              { staticClass: "question-answers" },
              _vm._l(_vm.model.answers, function(answer, idx) {
                return _c("div", { key: idx, staticClass: "question-answer" }, [
                  _c("input", {
                    attrs: { type: "radio", name: "", id: "" },
                    domProps: { checked: answer.isCorrect }
                  }),
                  _c("label", {
                    attrs: { for: "question1" },
                    domProps: { innerHTML: _vm._s(answer.answer) }
                  })
                ])
              }),
              0
            ),
            _c("b-field", { attrs: { label: "תשובה מפורטת" } }, [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.model.detailedAnswer) }
              })
            ])
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }