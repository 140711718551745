var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [_vm._v("תצוגה מקדימה")]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c("div", { staticClass: "DialogReportPaid__Component__Loading" }, [
          _c(
            "div",
            { staticStyle: { padding: "56.25% 0 0 0", position: "relative" } },
            [
              _c("iframe", {
                staticStyle: {
                  border: "none",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  height: "100%",
                  width: "100%"
                },
                attrs: {
                  loading: "lazy",
                  title: "",
                  src: _vm.link,
                  allow:
                    "accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;",
                  frameborder: "0",
                  allowfullscreen: ""
                }
              })
            ]
          )
        ])
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }