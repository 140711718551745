<template>
  <div v-if="ready" class="ClientWidget__Component">
    <div v-if="clientName">
      <div>{{ clientName }}</div>
      <div>ח.פ: {{ idNumber }}</div>
      <div v-if="clientStreet && clientCity">
        {{ clientStreet }} {{ clientHouseNumber }} , {{ clientCity }}
      </div>
      <div v-if="City">עיר: {{ City }}</div>
      <div v-if="Street">רחוב: {{ Street }}</div>
      <div v-if="HouseNumber">מספר בית: {{ HouseNumber }}</div>
      <div v-if="ZipCode">מיקוד: {{ ZipCode }}</div>
      <div v-if="typeof hasOpeningPage === 'boolean'">
        האם קיים דף ראשון בהסכם: {{ hasOpeningPage ? "כן" : "לא" }}
      </div>
      <div v-if="typeof HasGeneralContractForm === 'boolean'">
        האם קיימת הצהרה כללית: {{ HasGeneralContractForm ? "כן" : "לא" }}
      </div>
      <div v-if="typeof HasDriversLicense === 'boolean'">
        האם קיים רישיון נהיגה: {{ HasDriversLicense ? "כן" : "לא" }}
      </div>
      <div v-if="UpdateDate">תאריך עדכון: {{ displayDate() }}</div>
      <span v-if="fetch" class="loading">
        <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
      </span>
    </div>
    <div v-if="ready && !clientName">אין לקוח משוייך</div>
    <a v-if="isAdmin && clientName" @click="openModal">
      <span class="preview">ערוך</span>
    </a>
    <a v-if="!isAdmin && clientName" :href="`/clients/${clientId}`">
      <span class="preview">הצג</span>
    </a>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import DialogUpdateProfile from "../DialogUpdateProfile.vue";
// import ProfileService from "../../services/ProfileService";
import LeasingCompaniesService from "../../services/LeasingCompaniesService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      clientName: null,
      clientId: null,
      idNumber: null,
      data: null,
      loading: false,
      clientHouseNumber: null,
      clientStreet: null,
      clientCity: null,
      hasOpeningPage: null,
      HasGeneralContractForm: null,
      HasDriversLicense: null,
      UpdateDate: null,
      ZipCode: null,
      Street: null,
      HouseNumber: null,
      City: null,
      isAdmin: null
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["Profile"]),
    };
  },
  created() {
    this.isAdmin = this.$store.state.auth?.user?.claims?.isAdmin === "True";
  },
  methods: {
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    openModal() {
      Modal.open({
        component: DialogUpdateProfile,
        customClass: "entity-modal",
        props: {
          profileId: this.clientId,
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    init() {
      if (this.Profile) {
        this.clientName = this.Profile?.Name;
        this.clientId = this.Profile?.Id;
        this.idNumber = this.Profile?.IdNumber;
        this.ZipCode = this.Profile?.ZipCode;
        this.Street = this.Profile?.Street;
        this.HouseNumber = this.Profile?.HouseNumber;
        this.City = this.Profile?.City;
        LeasingCompaniesService.getClient(this.clientId).then((r) => {
          // ProfileService.getClientAdmin(this.clientId).then((r) => {
          if (r.data) {
            this.hasOpeningPage = r.data.Client?.HasOpeningPage;
            this.HasDriversLicense = r.data.Client?.HasDriversLicense;
            this.UpdateDate = r.data.Client?.UpdateDate;
            this.HasGeneralContractForm = r.data.Client?.HasGeneralContractForm;
            this.clientHouseNumber = r.data.Profile?.HouseNumber;
            this.clientStreet = r.data.Profile?.Street;
            this.clientCity = r.data.Profile?.City;
          }
        });
      }
      this.$emit("onReady", true);
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ClientWidget__Component {
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
