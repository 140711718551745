<template>
  <div class="Accordion__Component">
    <div class="Accordion__Component__Head" @click="open = !open">
      <div class="Accordion__Component__Toggle value" :class="{ open: open }">
        <img src="@/assets/down-arrow.svg" alt="" />
      </div>
      <div class="Accordion__Component__Title">
        <slot name="title"></slot>
      </div>
    </div>
    <div class="Accordion__Component__Content" v-show="open">
      <slot name="content"></slot>
      <template v-if="loading && (!loadingType || loadingType === 'text')">
        <content-loader
          :width="340"
          :height="84"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="118" y="10" rx="3" ry="3" width="67" height="11" />
          <rect x="196" y="10" rx="3" ry="3" width="140" height="11" />
          <rect x="22" y="52" rx="3" ry="3" width="400" height="11" />
          <rect x="21" y="73" rx="3" ry="3" width="500" height="11" />
          <rect x="24" y="84" rx="3" ry="3" width="460" height="137" />
          <rect x="22" y="30" rx="3" ry="3" width="140" height="11" />
          <rect x="170" y="31" rx="3" ry="3" width="173" height="11" />
        </content-loader>
      </template>
      <template v-if="loading && loadingType === 'photos'">
        <content-loader
          :width="800"
          :height="575"
          :speed="2"
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
          <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
          <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
          <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
          <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
          <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
          <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
          <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
          <circle cx="286" cy="536" r="12" />
          <circle cx="319" cy="535" r="12" />
          <circle cx="353" cy="535" r="12" />
          <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
          <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
          <circle cx="210" cy="535" r="12" />
          <circle cx="428" cy="536" r="12" />
        </content-loader>
      </template>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'Accordion',
  components: {
    ContentLoader,
  },
  props: {
    openOnStart: Boolean,
    loading: Boolean,
    loadingType: String,
  },
  data() {
    return {
      open: this.openOnStart,
    };
  },
};
</script>

<style scoped lang="scss">
.Accordion__Component__Head {
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
}
.Accordion__Component__Toggle {
  width: 13px;
  margin: 0 20px;
  transform: rotateZ(90deg);
  transform-origin: center;
  transition: transform .1s linear;
}
.Accordion__Component__Toggle.open {
  transform: rotateZ(0);
}
.Accordion__Component__Title {
  font-weight: bold;
  font-size: 14px;
  margin-inline-start: 5px;
  margin-bottom: 2px;
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.Accordion__Component__Content {
  display: flex;
  padding: 15px 25px;
  font-size: 14px;
  text-align: start;
  width: 100%;
  flex-direction: column;
}
</style>
