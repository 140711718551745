<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">שיוך וידאו ליחידת לימוד</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">

                    <FieldInlineText label="שם" v-model="model.name" />

                    <FieldInlineSelect label="יחידת לימוד" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getLessons" v-model="model.lessonId" />

                    <FieldInlineSelect :alert-not-exist="true" :hide-hover="true" label="בחירת וידאו" :clearable="true"
                        :filterable="true" placeholder="הקלד שם וידאו לחיפוש" :options="[]"
                        :fetch-options="onSearchVehicles" @onChange="onChange" v-model="model.videoId" />

                    <FieldInlineSelect label="מקבץ חקיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getlawCollections" v-model="model.lawCollectionId" />

                    <FieldInlineSelect label="תקצירי פסיקה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getVerdictCollections" v-model="model.verdictCollectionId" />

                    <FieldInlineSelect label="קטגוריה" @onChange="onChange" :filterable="true" :clearable="true"
                        :optionsAsync="getCategories" v-model="model.categoryId" />

                    <FieldInlineText label="נושא" v-model="model.videoCategory" />

                    <b-field label="חומרי עזר">
                        <ckeditor :editor="editor" placeholder="'חומרי עזר'" v-model="model.supportingMaterials"
                            :config="editorConfig"></ckeditor>
                    </b-field>

                    <!-- <div style="display: flex; flex-direction: column; margin-top: 15px;">
                        <div>
                            <b-checkbox style="gap: 10px" v-model="model.status" label="" type="is-link is-light"
                                class="checkbox">
                                {{ model.status ? ' פעיל' : 'לא פעיל' }}
                            </b-checkbox>
                        </div>

                        <div>
                            <b-checkbox style="gap: 10px" v-model="model.isInteractiveVideo" label=""
                                type="is-link is-light" class="checkbox">
                                {{ model.isInteractiveVideo ? 'אינטראקטיבי' : 'לא אינטראקטיבי' }}
                            </b-checkbox>
                        </div>
                    </div> -->

                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import AdminService from "@/services/AdminService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";
import LessonService from "../services/LessonService";

export default {
    name: "DialogReportDriverAssign",
    props: ["onSuccess", "store", "lessonId"],
    components: {
        // NewActivity,
        FieldInlineSelect,
        FieldInlineText
    },
    created() {
        if (this.lessonId) this.model.lessonId = this.lessonId;
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            loading: false,
            model: {
                name: null,
                lessonId: null,
                videoId: null,
                lawCollectionId: null,
                verdictCollectionId: null,
                categoryId: null,
                videoCategory: '',
                supportingMaterials: null
            },
            editor: ClassicEditor,
            editorConfig: {
                // The configuration of the editor.
                language: {
                    ui: 'he',
                }
            }
        };
    },
    computed: {
    },
    methods: {
        save() {
            this.submitted = true;
            if (this.model.name) {
                if (this.lessonId) this.model.lessonId = +this.model.lessonId.Value;
                this.isLoading = true;
                LessonService.addLessonVideo(this.store.state.auth.course.id, {
                    ...this.model
                })
                    .then(() => {
                        // this.reports.map((t, idx) => (
                        //     t.activities.unshift(r[idx].data)
                        // ));
                        this.$emit("close");
                        if (this.onSuccess) this.onSuccess();
                        window.location.reload();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { },
        getLessons() {
            return () => CoursesService.getCourse(this.store.state.auth.course.id,
                {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    ResultType: 3
                },
                false).then((r) => r.data.items.map((u) => ({
                    Value: u.id,
                    Text: `${u.id} - ${u.name}`
                }))
                );
        },
        getCategories() {
            return () =>
                CoursesService.getCategories().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getlawCollections() {
            return () =>
                CoursesService.getLawCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        getVerdictCollections() {
            return () =>
                CoursesService.getVerdictCollections().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
        onSearchVehicles({ search, loading, setData, setValue }) {
            if (search.length > 3) {
                loading(true);
                return AdminService.getVideosInBank({
                    Terms: search
                })
                    .then((r) => {
                        if (!r.data.items.length) {
                            setValue({ Value: -1, Text: search });
                        } else {
                            setData(r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` })));
                        }
                    })
                    .finally(() => {
                        loading(false);
                    });
            }

            return null;
        },
    },
};
</script>

<style lang="scss"></style>
