var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "DetailsActionButtons__Component" },
    [
      _c(
        "b-dropdown",
        { attrs: { "aria-role": "list" } },
        [
          _c(
            "b-dropdown-item",
            { attrs: { "aria-role": "listitem" }, on: { click: _vm.newEmail } },
            [_vm._v("אימייל")]
          ),
          _c("b-dropdown-item", { attrs: { "aria-role": "listitem" } }, [
            _vm._v("שיחה")
          ]),
          _c("b-dropdown-item", { attrs: { "aria-role": "listitem" } }, [
            _vm._v("פגישה")
          ])
        ],
        1
      ),
      _c("div", { staticClass: "DetailsActionButtons__Component__Item" }, [
        _c(
          "div",
          {
            staticClass: "DetailsActionButtons__Component__Item__Icon",
            on: { click: _vm.newTask }
          },
          [_c("b-icon", { attrs: { icon: "calendar-plus" } })],
          1
        ),
        _c(
          "span",
          { staticClass: "DetailsActionButtons__Component__Item__Text" },
          [_vm._v("משימה")]
        )
      ]),
      _c("div", { staticClass: "DetailsActionButtons__Component__Item" }, [
        _c(
          "div",
          {
            staticClass: "DetailsActionButtons__Component__Item__Icon",
            on: { click: _vm.newNote }
          },
          [_c("b-icon", { attrs: { icon: "message-bulleted" } })],
          1
        ),
        _c(
          "span",
          { staticClass: "DetailsActionButtons__Component__Item__Text" },
          [_vm._v("הערה")]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }