var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", {}, [
        _vm.loading
          ? _c(
              "span",
              { staticClass: "loading" },
              [
                _c("ClipLoader", {
                  attrs: { color: "#2e3f50", loading: _vm.loading, size: 20 }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "edit-section" }, [
          _c("a", { on: { click: _vm.addLectureStudent } }, [
            _vm._v("רישום תלמיד קיים להרצאה זו")
          ]),
          _c("a", { on: { click: _vm.exportLectureStudents } }, [
            _vm._v("ייצוא לאקסל")
          ])
        ]),
        _vm.lectureUsers && _vm.lectureUsers.length
          ? _c(
              "div",
              { staticClass: "StudentGroups__Container" },
              _vm._l(_vm.lectureUsers, function(user) {
                return _c(
                  "div",
                  {
                    key: user.id,
                    staticClass: "StudentGroupsWidget__Component"
                  },
                  [
                    _c("div", [
                      _vm._v(
                        _vm._s(user.privateName) +
                          " " +
                          _vm._s(user.lastName) +
                          " - " +
                          _vm._s(user.phone1) +
                          " " +
                          _vm._s(_vm.parseDate(user.createdate))
                      )
                    ]),
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.removeUserFromLecture(user.id)
                          }
                        }
                      },
                      [_vm._v("ביטול רישום")]
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e(),
        !_vm.loading && !_vm.lectureUsers.length
          ? _c("div", [_vm._v("אין תלמידים רשומים להרצאה")])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }