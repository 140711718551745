var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slideUp" } }, [
    _vm.open
      ? _c(
          "div",
          {
            staticClass: "PendingChangesDialog__Component",
            class: _vm.getDirection
          },
          [
            _c(
              "b-button",
              {
                staticClass: "save",
                attrs: { disabled: _vm.isLoading, loading: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.$emit("onSave")
                  }
                }
              },
              [_vm._v("שמירה")]
            ),
            _c(
              "b-button",
              {
                staticClass: "cancel",
                attrs: { disabled: _vm.isLoading },
                on: {
                  click: function($event) {
                    return _vm.$emit("onCancel")
                  }
                }
              },
              [_vm._v("ביטול")]
            ),
            _c("p", [_vm._v("שונו " + _vm._s(_vm.amount) + " שדות")])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }