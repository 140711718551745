<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">עדכון שאלת חשיפה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section v-if="model" class="modal-card-body">
                <FieldInlineText label="תווית יחידה" @onChange="onChange" v-model="model.unitDisplayName" />

                <b-field label="שאלה">
                    <ckeditor @input="onChange" :editor="editor" v-model="model.question" :config="editorConfig">
                    </ckeditor>
                </b-field>

                <FieldInlineSelect label="תשובה" @onChange="onChange" :null-is-value="true" :filterable="false" :clearable="false" :options="[{ Value: true, Text: 'נכון' },
                { Value: '0', Text: 'לא נכון' }]" v-model="model.answer" />

                <FieldInlineText label="פתרון" @onChange="onChange" v-model="model.detailedAnswer" />

                <FieldInlineText label="רמת קושי" type="number" @onChange="onChange" v-model="model.level" />

                <div class="checkbox-container">
                    <b-switch class="ltr" @input="onChange" v-model="model.status">{{
                        model.status ? "פעיל" : "לא פעיל"
                        }}</b-switch>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineText from "./Fields/FieldInlineText.vue";
import FieldInlineSelect from "./Fields/FieldInlineSelect.vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CoursesService from '../services/CoursesService';

export default {
    name: "DialogReportDriverAssign",
    props: ["question", "store"],
    components: {
        FieldInlineText,
        FieldInlineSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        },
        expoChapterId() {
            return this.store.state.expoChapter.id;
        }
    },
    created() {
        this.model = this.question;
        if (!this.model.answer) {
            this.model.answer = '0';
        }
    },
    data() {
        return {
            isLoading: false,
            model: null,
            editor: ClassicEditor,
            editorConfig: {
                language: {
                    ui: 'he',
                }
            }
        };
    },
    methods: {
        save() {
            if (this.model.question) {
                if (this.model.answer === '0') this.model.answer = false;
                this.isLoading = true;
                CoursesService.updateExpoChapterQuestion(this.expoChapterId, {
                    ...this.model
                })
                    .then(() => {
                        // this.init(refresh);
                        this.$emit('onSave');
                        this.$buefy.toast.open({
                            type: "is-success",
                            message: "נשמר בהצלחה!",
                            duration: 4000,
                        });
                    })
                    .catch(() => {
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: "קרתה תקלה, השמירה לא התבצעה!",
                            duration: 8000,
                        });
                        refresh();
                    })
                    .finally(() => {
                        this.$emit('close');
                        this.isLoading = false;
                    });
            }
        },
        onChange() { },
        getLessons() {
            return () =>
                CoursesService.getCourse(this.courseId, {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    SortedBy: "id",
                    ResultType: 3
                }, true).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
    },
};
</script>

<style scoped lang="scss">
.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.items {
    flex-direction: column;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
    cursor: pointer;
}

.items {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
        flex: 1;
    }

    input {
        width: 50px;
    }
}

.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
}

.clicked {
    background-color: gray;
}
</style>
