var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c("div", { staticClass: "ClientWidget__Component" }, [
        _vm.clientName
          ? _c("div", [
              _c("div", [_vm._v(_vm._s(_vm.clientName))]),
              _c("div", [_vm._v("ח.פ: " + _vm._s(_vm.idNumber))]),
              _vm.clientStreet && _vm.clientCity
                ? _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.clientStreet) +
                        " " +
                        _vm._s(_vm.clientHouseNumber) +
                        " , " +
                        _vm._s(_vm.clientCity) +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.City
                ? _c("div", [_vm._v("עיר: " + _vm._s(_vm.City))])
                : _vm._e(),
              _vm.Street
                ? _c("div", [_vm._v("רחוב: " + _vm._s(_vm.Street))])
                : _vm._e(),
              _vm.HouseNumber
                ? _c("div", [_vm._v("מספר בית: " + _vm._s(_vm.HouseNumber))])
                : _vm._e(),
              _vm.ZipCode
                ? _c("div", [_vm._v("מיקוד: " + _vm._s(_vm.ZipCode))])
                : _vm._e(),
              typeof _vm.hasOpeningPage === "boolean"
                ? _c("div", [
                    _vm._v(
                      " האם קיים דף ראשון בהסכם: " +
                        _vm._s(_vm.hasOpeningPage ? "כן" : "לא") +
                        " "
                    )
                  ])
                : _vm._e(),
              typeof _vm.HasGeneralContractForm === "boolean"
                ? _c("div", [
                    _vm._v(
                      " האם קיימת הצהרה כללית: " +
                        _vm._s(_vm.HasGeneralContractForm ? "כן" : "לא") +
                        " "
                    )
                  ])
                : _vm._e(),
              typeof _vm.HasDriversLicense === "boolean"
                ? _c("div", [
                    _vm._v(
                      " האם קיים רישיון נהיגה: " +
                        _vm._s(_vm.HasDriversLicense ? "כן" : "לא") +
                        " "
                    )
                  ])
                : _vm._e(),
              _vm.UpdateDate
                ? _c("div", [
                    _vm._v("תאריך עדכון: " + _vm._s(_vm.displayDate()))
                  ])
                : _vm._e(),
              _vm.fetch
                ? _c(
                    "span",
                    { staticClass: "loading" },
                    [
                      _c("ClipLoader", {
                        attrs: {
                          color: "#2e3f50",
                          loading: _vm.loading,
                          size: 20
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.ready && !_vm.clientName
          ? _c("div", [_vm._v("אין לקוח משוייך")])
          : _vm._e(),
        _vm.isAdmin && _vm.clientName
          ? _c("a", { on: { click: _vm.openModal } }, [
              _c("span", { staticClass: "preview" }, [_vm._v("ערוך")])
            ])
          : _vm._e(),
        !_vm.isAdmin && _vm.clientName
          ? _c("a", { attrs: { href: "/clients/" + _vm.clientId } }, [
              _c("span", { staticClass: "preview" }, [_vm._v("הצג")])
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }