<template>
  <div class="Entity" :class="{ 'Entity-Preview': preview }">
    <div class="Entity__Details">
      <div class="Entity__Details__PageOptions">
        <div v-if="!preview" class="Entity__Details__PageOptions__Back" @click="back()">
          <i><img src="@/assets/down-arrow.svg" alt="" /></i>
          {{ backButtonText }}
        </div>
        <!-- <b-dropdown aria-role="list" v-if="actions && !preview">
          <template #trigger="{ active }">
            <div v-if="!preview" class="Entity__Details__PageOptions__Actions">
              <span>פעולות</span>
              <b-icon :icon="active ? 'menu-up' : 'menu-down'" />
            </div>
          </template>

          <b-dropdown-item
            class="dropdown-iteam-to-right"
            v-for="(action, index) in actions"
            :key="index"
            @click="action.onClick"
            aria-role="listitem"
            >{{ action.text }}</b-dropdown-item
          >
        </b-dropdown> -->
      </div>
      <div class="Entity__Details__BaseDetails">
        <!-- <slot name="mandatory" v-if="entityReady"></slot> -->
        <template v-if="entityReady">
          <div class="Entity__Details__BaseDetails__Title">
            <span class="Entity__Details__BaseDetails__Title__Text">{{
              title
            }}</span>
          </div>
          <!-- <div class="Entity__Details__BaseDetails__Stage">
            <span class="ltr">שלב:</span>
            <span class="Entity__Details__BaseDetails__Stage__Value
                        value has-text-weight-bold"
              >ממתין לתשובה ממנהל</span
            >
          </div> -->
          <slot name="mandatory"></slot>
        </template>
        <template v-if="!entityReady">
          <content-loader :width="340" :height="84" :speed="2" primaryColor="#f3f3f3" secondaryColor="#ecebeb">
            <rect x="118" y="10" rx="3" ry="3" width="67" height="11" />
            <rect x="196" y="10" rx="3" ry="3" width="140" height="11" />
            <rect x="24" y="84" rx="3" ry="3" width="460" height="137" />
            <rect x="22" y="30" rx="3" ry="3" width="140" height="11" />
            <rect x="170" y="31" rx="3" ry="3" width="173" height="11" />
          </content-loader>
        </template>
      </div>
      <!-- <div v-if="!preview" class="Entity__Details__BaseDetails__Actions">
        <DetailsActionButtons @onNewTask="newTask" @onNewNote="newNote" />
      </div> -->
      <div class="Entity__Details__BaseDetails__About">
        <Accordion :open-on-start="true" :loading="!entityReady">
          <template v-slot:title> {{ innerComponentTitle }}</template>
          <template v-slot:content>
            <div v-if="entityReady" class="Entity__Details__BaseDetails__About__Content">
              <component @onFormChange="onFormChange" :readOnly="readOnly" :entity-id="entityId" ref="form"
                :OnProps="OnProps" :is="innerComponent" />
            </div>
          </template>
        </Accordion>
      </div>
    </div>
    <div v-if="!preview" class="Entity__Activity">
      <Activity v-if="entityType === 'lesson'" ref="activity" :activities="activities" :loading="loadingActivities"
        :entityType="entityType" :entityId="entityId" />
      <ExerciseForm v-if="entityType === 'exercise'" ref="exerciseForm" :entityType="entityType" :entityId="entityId" />
      <QuestionForm v-if="entityType === 'question'" ref="questionForm" :entityType="entityType" :entityId="entityId" />
      <UserExercises v-if="entityType === 'user'" ref="userExercises" :entityType="entityType" :entityId="entityId" />
      <ExpoChapterPage v-if="entityType === 'expoChapter'" ref="expoChapter" :entityType="entityType" :entityId="entityId" />
      <GroupEntityPage v-if="entityType === 'group'" ref="expoChapter" :entityType="entityType" :entityId="entityId" />
      <LawCollectionEntityPage v-if="entityType === 'lawCollection'" ref="lawCollection" :entityType="entityType" :entityId="entityId" />
    </div>
    <div v-if="!isEntityPage || (isEntityPage && showRelations)" class="Entity__Relations">
      <div v-for="(widget, index) in widgetsList" :key="index">
        <div class="Entity__Relations__Item" v-if="!widget.isHidden">
          <Accordion :open-on-start="index === 0 || widget.open" :loading="!widget.ready || !entityReady"
            :loading-type="widget.loadingType">
            <template v-slot:title>
              <span>
                {{ widget.title }}
                <span v-if="widget.multiple">({{ widget.titleCount }})</span>
              </span>

              <button @click.stop="setSideBarContent(widget)" class="Entity__Relations__Item__Edit"
                v-if="widget.editComponent && !preview">
                עריכה
              </button>

              <button @click.stop="showModal(widget)" class="Entity__Relations__Item__Edit"
                v-if="!widget.addable && widget.modal && !preview && (!widget.isAdminEditable || isAdmin)">
                ערוך
              </button>

              <button @click.stop="showModal(widget)" class="Entity__Relations__Item__Edit"
                v-if="widget.addable && !preview && (!widget.isAdminEditable || isAdmin)">
                ערוך
              </button>
            </template>
            <template v-slot:content>
              <component :is="widget.type" :widget="widget" :namespace="widget.namespace" :field="widget.field"
                :ready="entityReady" :fetch="widget.fetch" :delegate="widget.delegate" :query="widget.query"
                @onReady="(data) => onWidgetReady(widget, data)" />
            </template>
          </Accordion>
        </div>
      </div>
    </div>
    <EntitySaveDialog :open="saveDialog" :is-loading="isSaving" @onSave="onFormSave" @onCancel="onFormDiscard"
      :amount="changesAmount" />

    <SideMenu v-if="isSideBarComponentOpen" :width="'420px'">
      <component :is="sideBarComponent.component" @close="isSideBarComponentOpen = false" />
    </SideMenu>
  </div>
</template>

<script>
import DetailsActionButtons from "@/components/DetailsActionButtons.vue";
import Activity from "@/components/Activity.vue";
import ExerciseForm from "@/components/ExerciseForm.vue";
import QuestionForm from "@/components/QuestionForm.vue";
import UserExercises from "@/components/UserExercises.vue";
import ExpoChapterPage from "@/components/ExpoChapterPage.vue";
import GroupEntityPage from "@/components/GroupEntityPage.vue";
import LawCollectionEntityPage from "@/components/LawCollectionEntityPage.vue";
import Accordion from "@/components/Accordion.vue";
import EntitySaveDialog from "@/components/EntitySaveDialog.vue";
import SideMenu from "@/components/SideMenu.vue";
import EntityItemsService from "@/services/EntityItemsService";
import { ContentLoader } from "vue-content-loader";
import * as widgets from "@/components/Widgets";
import { ModalProgrammatic as Modal } from "buefy";

export default {
  name: "Entity",
  props: [
    "entityType",
    "entityId",
    "title",
    "backButtonText",
    "backButtonUrl",
    "widgets",
    "entityReady",
    "innerComponent",
    "innerComponentTitle",
    "preview",
    "readOnly",
    "actions",
    "OnProps",
    "hideInnerComponent",
    "isEntityPage",
    "showRelations"
  ],
  components: {
    ...widgets.default,
    DetailsActionButtons,
    Accordion,
    EntitySaveDialog,
    Activity,
    ExerciseForm,
    QuestionForm,
    UserExercises,
    ExpoChapterPage,
    GroupEntityPage,
    LawCollectionEntityPage,
    ContentLoader,
    SideMenu,
  },
  computed: {
    mappedWidgets() {
      const mapped = this.widgets.map((i) => ({
        ...i,
        ready: false,
        titleCount: 0,
      }));
      return mapped;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
  },
  provide() {
    return {
      EntitySaveDialog: this.showSaveDialog,
    };
  },
  data() {
    return {
      sideBarComponent: null,
      isSideBarComponentOpen: false,
      saveDialog: false,
      loadingEntity: true,
      loadingActivities: true,
      propertyValue: null,
      documents: [],
      activities: [],
      changesAmount: 0,
      widgetsList: null,
      isSaving: false,
    };
  },
  created() {
    // this.getActivities();
    this.widgetsList = this.mappedWidgets;
  },
  methods: {
    newTask() {
      this.$refs.activity.newTask();
    },
    newNote() {
      this.$refs.activity.newNote();
    },
    onWidgetReady(widget, data) {
      this.$set(widget, "titleCount", data?.titleCount ?? 0);
      this.$set(widget, "ready", true);
    },
    showModal(widget) {
      Modal.open({
        component: widget.modal,
        customClass: "entity-modal",
        props: {
          entityId: [this.entityId],
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    setSideBarContent(widget) {
      this.sideBarComponent = {
        component: widget.editComponent,
        // model: widget.editMap(this.model),
      };
      this.isSideBarComponentOpen = true;
    },
    onFormChange(changes) {
      this.saveDialog = true;
      this.changesAmount = changes;
    },
    onFormSave() {
      this.$refs.form.save();
      this.$emit("onFormSave", {
        model: this.$refs.form.model,
        closeDialog: () => {
          this.saveDialog = false;
        },
        restoreState: () => {
          this.$refs.form.cancel();
        },
        refresh: () => {
          this.$refs.form.refresh();
        },
        setRestorePoint: () => {
          this.$refs.form.setRestorePoint();
        },
        saving: (value) => {
          this.isSaving = value;
        },
      });
    },
    onFormDiscard() {
      this.$emit("onFormDiscard", {
        closeDialog: () => {
          this.saveDialog = false;
          this.isSaving = false;
        },
        restoreState: () => {
          this.$refs.form.cancel();
        },
      });
    },
    back() {
      this.$router.replace({ path: `/${this.backButtonUrl}` });
    },
    getActivities() {
      EntityItemsService.getEntity(this.entityType, this.entityId)
        .then((r) => {
          this.activities = r.data;
        })
        .finally(() => {
          this.loadingActivities = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.Entity {
  height: calc(100vh - 50px);
  display: flex;
  overflow: auto;

  &.Entity-Preview {
    flex-direction: column;
    height: unset;

    .Entity__Details,
    .Entity__Relations {
      flex: auto;
    }
  }

  .Entity__Details {
    flex: 1;
    flex: 0 0 400px;
    border-inline-end: 1px solid #cbd6e2;
    overflow-y: auto;
    height: 100%;
  }

  .Entity__Activity {
    background: #dbe2e67a;
    overflow-y: auto;
    width: 100%;
    min-width: 500px;
  }

  .Entity__Relations {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    flex: 0 0 400px;

    .Entity__Relations__Item {
      border-top: 1px solid #cbd6e2;
      // border-inline-start: 1px solid #cbd6e2;
      padding: 19px 0;

      .Entity__Relations__Item__Edit {
        margin-left: 20px;
        font-weight: 500;
        background-color: transparent;
        border: none;
        color: #57b7ca;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.Entity__Details__PageOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  // .Entity__Details__PageOptions__Actions__Iteams{
  //   background: red;
  // }

  .Entity__Details__PageOptions__Back {
    border-radius: 3px;
    padding: 10px 25px;
    color: #0091ae;
    position: relative;
    right: -5px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    i {
      width: 11px;
      display: inline-block;
      vertical-align: middle;

      img {
        transform: rotateZ(-90deg);
      }
    }
  }

  .Entity__Details__PageOptions__Actions {
    color: #0091ae;
    font-size: 15px;
    margin-inline-end: 10px;
    display: flex;
    cursor: pointer;
  }
}

.Entity__Details__BaseDetails {
  width: 100%;
  padding: 20px 20px;
  text-align: start;

  .Entity__Details__BaseDetails__Title {
    font-weight: bold;
  }

  div {
    width: 100%;

    input {
      width: 100%;
    }
  }

  .Entity__Details__BaseDetails__Stage {
    margin-top: 5px;
    font-size: 15px;
  }
}

.Entity__Details__BaseDetails__About {
  // border-top: 1px solid #cbd6e2;
  padding: 20px 0;

  .Entity__Details__BaseDetails__About__Content {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;

    &>div,
    & ::v-deep>div {
      // margin: 5px 0;
      width: 100%;
    }
  }
}

body ::v-deep .modal-background {
  background-color: rgb(10, 10, 10 / 12%);
}
</style>
