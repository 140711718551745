<template>
  <div class="DetailsActionButtons__Component">
    <b-dropdown aria-role="list">
            <b-dropdown-item aria-role="listitem" @click="newEmail" >אימייל</b-dropdown-item>
            <b-dropdown-item aria-role="listitem">שיחה</b-dropdown-item>
            <b-dropdown-item aria-role="listitem">פגישה</b-dropdown-item>
        </b-dropdown>
    <div class="DetailsActionButtons__Component__Item">
      <div class="DetailsActionButtons__Component__Item__Icon" @click="newTask">
        <b-icon icon="calendar-plus"  />
      </div>
      <span class="DetailsActionButtons__Component__Item__Text">משימה</span>
    </div>
    <div class="DetailsActionButtons__Component__Item">
      <div class="DetailsActionButtons__Component__Item__Icon" @click="newNote">
        <b-icon icon="message-bulleted" />
      </div>
      <span class="DetailsActionButtons__Component__Item__Text">הערה</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsActionButtons',
  methods: {
    newTask() {
      this.$emit('onNewTask');
    },
    newEmail() {
      this.$emit('onNewTask');
    },
    newNote() {
      this.$emit('onNewNote');
    },
  },
};
</script>

<style scoped lang="scss">
.DetailsActionButtons__Component {
  display: flex;
  justify-content: start;
  align-items: center;
  border-bottom: 1px solid #CBD6E2;
  padding-bottom: 30px;
  padding-right: 10px;
}
.DetailsActionButtons__Component__Item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.DetailsActionButtons__Component__Item__Icon {
  width: 40px;
  height: 40px;
  background: #eaf0f6;
  border-radius: 50%;
  margin: 0 8px;
  border: 1px solid #dae2eb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DetailsActionButtons__Component__Item:hover .DetailsActionButtons__Component__Item__Icon  {
    background: #f7f7f7;
}
.DetailsActionButtons__Component__Item__Text {
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
}
</style>
