var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("הוספת שאלה לפרק")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          [
            _c(
              "b-radio",
              {
                attrs: { name: "name", "native-value": "manual" },
                model: {
                  value: _vm.radio,
                  callback: function($$v) {
                    _vm.radio = $$v
                  },
                  expression: "radio"
                }
              },
              [_vm._v(" ידני ")]
            ),
            _c(
              "b-radio",
              {
                attrs: { name: "name", "native-value": "file" },
                model: {
                  value: _vm.radio,
                  callback: function($$v) {
                    _vm.radio = $$v
                  },
                  expression: "radio"
                }
              },
              [_vm._v(" מקובץ ")]
            ),
            _vm.radio === "manual"
              ? _c(
                  "div",
                  [
                    _c("FieldInlineText", {
                      attrs: { label: "תווית יחידה" },
                      model: {
                        value: _vm.model.unitDisplayName,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "unitDisplayName", $$v)
                        },
                        expression: "model.unitDisplayName"
                      }
                    }),
                    _c("FieldInlineText", {
                      attrs: { label: "שאלה" },
                      model: {
                        value: _vm.model.question,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "question", $$v)
                        },
                        expression: "model.question"
                      }
                    }),
                    _c("FieldInlineSelect", {
                      attrs: {
                        label: "תשובה",
                        filterable: false,
                        clearable: false,
                        options: [
                          { Value: true, Text: "נכון" },
                          { Value: false, Text: "לא נכון" }
                        ],
                        required: "true"
                      },
                      model: {
                        value: _vm.model.answer,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "answer", $$v)
                        },
                        expression: "model.answer"
                      }
                    }),
                    _c("FieldInlineText", {
                      attrs: { label: "פתרון" },
                      model: {
                        value: _vm.model.detailedAnswer,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "detailedAnswer", $$v)
                        },
                        expression: "model.detailedAnswer"
                      }
                    }),
                    _c("FieldInlineText", {
                      attrs: { label: "רמת קושי", type: "number" },
                      model: {
                        value: _vm.model.level,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "level", $$v)
                        },
                        expression: "model.level"
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "checkbox-container",
                        staticStyle: { "margin-top": "10px" }
                      },
                      [
                        _c(
                          "b-switch",
                          {
                            staticClass: "ltr",
                            model: {
                              value: _vm.model.status,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "status", $$v)
                              },
                              expression: "model.status"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.model.status ? "פעיל" : "לא פעיל")
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "b-field",
                      { attrs: { label: "פורמט להורדה" } },
                      [
                        _c(
                          "b-button",
                          { on: { click: _vm.downloadExpoFormat } },
                          [_vm._v("הורד")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "בחירת קובץ (יש לטעון קובץ בפורמט שניתן בלבד!)"
                        }
                      },
                      [
                        _c(
                          "b-upload",
                          {
                            staticClass: "file-label",
                            model: {
                              value: _vm.file,
                              callback: function($$v) {
                                _vm.file = $$v
                              },
                              expression: "file"
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "file-cta" },
                              [
                                _c("b-icon", {
                                  staticClass: "file-icon",
                                  attrs: { icon: "upload" }
                                }),
                                _c("span", { staticClass: "file-label" }, [
                                  _vm._v("בחירת קובץ")
                                ])
                              ],
                              1
                            ),
                            _vm.file
                              ? _c("span", { staticClass: "file-name" }, [
                                  _vm._v(" " + _vm._s(_vm.file.name) + " ")
                                ])
                              : _vm._e()
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }