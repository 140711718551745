var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Accordion__Component" }, [
    _c(
      "div",
      {
        staticClass: "Accordion__Component__Head",
        on: {
          click: function($event) {
            _vm.open = !_vm.open
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "Accordion__Component__Toggle value",
            class: { open: _vm.open }
          },
          [
            _c("img", {
              attrs: { src: require("@/assets/down-arrow.svg"), alt: "" }
            })
          ]
        ),
        _c(
          "div",
          { staticClass: "Accordion__Component__Title" },
          [_vm._t("title")],
          2
        )
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.open,
            expression: "open"
          }
        ],
        staticClass: "Accordion__Component__Content"
      },
      [
        _vm._t("content"),
        _vm.loading && (!_vm.loadingType || _vm.loadingType === "text")
          ? [
              _c(
                "content-loader",
                {
                  attrs: {
                    width: 340,
                    height: 84,
                    speed: 2,
                    primaryColor: "#f3f3f3",
                    secondaryColor: "#ecebeb"
                  }
                },
                [
                  _c("rect", {
                    attrs: {
                      x: "118",
                      y: "10",
                      rx: "3",
                      ry: "3",
                      width: "67",
                      height: "11"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "196",
                      y: "10",
                      rx: "3",
                      ry: "3",
                      width: "140",
                      height: "11"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "22",
                      y: "52",
                      rx: "3",
                      ry: "3",
                      width: "400",
                      height: "11"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "21",
                      y: "73",
                      rx: "3",
                      ry: "3",
                      width: "500",
                      height: "11"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "24",
                      y: "84",
                      rx: "3",
                      ry: "3",
                      width: "460",
                      height: "137"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "22",
                      y: "30",
                      rx: "3",
                      ry: "3",
                      width: "140",
                      height: "11"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "170",
                      y: "31",
                      rx: "3",
                      ry: "3",
                      width: "173",
                      height: "11"
                    }
                  })
                ]
              )
            ]
          : _vm._e(),
        _vm.loading && _vm.loadingType === "photos"
          ? [
              _c(
                "content-loader",
                {
                  attrs: {
                    width: 800,
                    height: 575,
                    speed: 2,
                    primaryColor: "#f3f3f3",
                    secondaryColor: "#ecebeb"
                  }
                },
                [
                  _c("rect", {
                    attrs: {
                      x: "537",
                      y: "9",
                      rx: "2",
                      ry: "2",
                      width: "140",
                      height: "10"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "14",
                      y: "30",
                      rx: "2",
                      ry: "2",
                      width: "667",
                      height: "11"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "12",
                      y: "58",
                      rx: "2",
                      ry: "2",
                      width: "211",
                      height: "211"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "240",
                      y: "57",
                      rx: "2",
                      ry: "2",
                      width: "211",
                      height: "211"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "467",
                      y: "56",
                      rx: "2",
                      ry: "2",
                      width: "211",
                      height: "211"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "12",
                      y: "283",
                      rx: "2",
                      ry: "2",
                      width: "211",
                      height: "211"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "240",
                      y: "281",
                      rx: "2",
                      ry: "2",
                      width: "211",
                      height: "211"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "468",
                      y: "279",
                      rx: "2",
                      ry: "2",
                      width: "211",
                      height: "211"
                    }
                  }),
                  _c("circle", { attrs: { cx: "286", cy: "536", r: "12" } }),
                  _c("circle", { attrs: { cx: "319", cy: "535", r: "12" } }),
                  _c("circle", { attrs: { cx: "353", cy: "535", r: "12" } }),
                  _c("rect", {
                    attrs: {
                      x: "378",
                      y: "524",
                      rx: "0",
                      ry: "0",
                      width: "52",
                      height: "24"
                    }
                  }),
                  _c("rect", {
                    attrs: {
                      x: "210",
                      y: "523",
                      rx: "0",
                      ry: "0",
                      width: "52",
                      height: "24"
                    }
                  }),
                  _c("circle", { attrs: { cx: "210", cy: "535", r: "12" } }),
                  _c("circle", { attrs: { cx: "428", cy: "536", r: "12" } })
                ]
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }