var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadVideos()
            }
          }
        },
        [_vm._v(" וידאו ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 2 },
          on: {
            click: function($event) {
              return _vm.loadExercises()
            }
          }
        },
        [_vm._v(" תרגולים ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 3 },
          on: {
            click: function($event) {
              return _vm.loadLaws()
            }
          }
        },
        [_vm._v(" מקבצי חקיקה ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 4 },
          on: {
            click: function($event) {
              return _vm.loadVerdicts()
            }
          }
        },
        [_vm._v(" תקצירי פסיקה ")]
      ),
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 5 },
          on: {
            click: function($event) {
              return _vm.loadBooks()
            }
          }
        },
        [_vm._v(" ספרים ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.showNewNote
          ? _c("new-activity", {
              ref: "noteActivity",
              attrs: { type: "Note" },
              on: { onClose: _vm.close, onSave: _vm.saveNote }
            })
          : _vm._e(),
        _vm.showNewTask
          ? _c("new-activity", {
              ref: "taskActivity",
              attrs: { type: "Task", "show-title": "true" },
              on: { onClose: _vm.closeTask, onSave: _vm.saveTask }
            })
          : _vm._e(),
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c("div", [
              _c(
                "div",
                { staticClass: "Activity__Component__TabsContent__Tasks" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-info" },
                          on: { click: _vm.organizeVideosSortOrder }
                        },
                        [_vm._v("סדר מחדש")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newLessonVideo }
                        },
                        [_vm._v("הוספת וידאו ליחידת הלימוד")]
                      )
                    ],
                    1
                  ),
                  _vm.videos && _vm.videos.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "Activity__Component__TabsContent__Tasks__Content"
                        },
                        [
                          _c("div", { staticClass: "section-container" }, [
                            _c("h1", [
                              _vm._v(
                                "ניהול וידאו ביחידת הלימוד: " +
                                  _vm._s(_vm.lesson.name) +
                                  " "
                              )
                            ]),
                            _c(
                              "div",
                              { staticClass: "document-table" },
                              [
                                _vm._m(0),
                                _vm._l(_vm.videos, function(video, idx) {
                                  return _c(
                                    "div",
                                    {
                                      key: idx,
                                      staticClass: "row",
                                      class: !video.status && "not-active"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [_vm._v(_vm._s(video.id))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item flex" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.setVideoSortOrder(
                                                    video,
                                                    idx,
                                                    1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "b-icon",
                                                {
                                                  staticClass:
                                                    "sort__order__button",
                                                  attrs: {
                                                    size: "is-small",
                                                    icon: "arrow-down"
                                                  }
                                                },
                                                [_vm._v("+")]
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "sort__order__value"
                                            },
                                            [_vm._v(_vm._s(video.sortOrder))]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.setVideoSortOrder(
                                                    video,
                                                    idx,
                                                    -1
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "b-icon",
                                                {
                                                  staticClass:
                                                    "sort__order__button",
                                                  attrs: {
                                                    size: "is-small",
                                                    icon: "arrow-up"
                                                  }
                                                },
                                                [_vm._v("-")]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [_vm._v(_vm._s(video.name))]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [
                                          _vm._v(
                                            _vm._s(video.lawCollectionName)
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [
                                          _vm._v(
                                            _vm._s(video.verdictCollectionName)
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              video.status ? "פעיל" : "לא פעיל"
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "column-item" },
                                        [_vm._v(_vm._s(video.categoryName))]
                                      ),
                                      _c("div", {
                                        staticClass: "column-item",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            video.supportingMaterials
                                          )
                                        }
                                      }),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b-tooltip",
                                            {
                                              attrs: {
                                                type: "is-white",
                                                label: "צפייה מקדימה"
                                              }
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "updateItem",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.previewVideo(
                                                        video.link
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("b-icon", {
                                                    attrs: { icon: "eye" }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          : _vm._e(),
        _vm.tab === 2
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-info" },
                          on: { click: _vm.organizeExercisesSortOrder }
                        },
                        [_vm._v("סדר מחדש")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newLessonExercise }
                        },
                        [_vm._v("הוספת תרגול ליחידת הלימוד")]
                      )
                    ],
                    1
                  ),
                  !_vm.isLoading && _vm.exercises && _vm.exercises.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "ניהול תרגולים ביחידת הלימוד: " +
                              _vm._s(_vm.lesson.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(1),
                            _vm._l(_vm.exercises, function(exercise, idx) {
                              return _c(
                                "div",
                                {
                                  key: idx,
                                  staticClass: "row",
                                  class: !exercise.status && "not-active"
                                },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.id))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "column-item flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setExerciseSortOrder(
                                                exercise,
                                                idx,
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              staticClass:
                                                "sort__order__button",
                                              attrs: {
                                                size: "is-small",
                                                icon: "arrow-down"
                                              }
                                            },
                                            [_vm._v("+")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "sort__order__value" },
                                        [_vm._v(_vm._s(exercise.sortOrder))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setExerciseSortOrder(
                                                exercise,
                                                idx,
                                                -1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              staticClass:
                                                "sort__order__button",
                                              attrs: {
                                                size: "is-small",
                                                icon: "arrow-up"
                                              }
                                            },
                                            [_vm._v("-")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.name))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.categoryName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        exercise.status ? "פעיל" : "לא פעיל"
                                      )
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "מעבר לתרגול"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "#1eb2d0" },
                                              attrs: {
                                                href: "/exercise/" + exercise.id
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  pack: "fa",
                                                  icon: "pen"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 3
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-info" },
                          on: { click: _vm.organizeLawsSortOrder }
                        },
                        [_vm._v("סדר מחדש")]
                      ),
                      _c(
                        "button",
                        { staticClass: "new", on: { click: _vm.newLessonLaw } },
                        [_vm._v("הוספת מקבץ חקיקה ליחידת הלימוד")]
                      )
                    ],
                    1
                  ),
                  !_vm.isLoading && _vm.laws && _vm.laws.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "ניהול מקבצי חקיקה ביחידת הלימוד: " +
                              _vm._s(_vm.lesson.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(2),
                            _vm._l(_vm.laws, function(law, idx) {
                              return _c(
                                "div",
                                {
                                  key: idx,
                                  staticClass: "row",
                                  class: !law.status && "not-active"
                                },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(law.id))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "column-item flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setLawSortOrder(
                                                law,
                                                idx,
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              staticClass:
                                                "sort__order__button",
                                              attrs: {
                                                size: "is-small",
                                                icon: "arrow-down"
                                              }
                                            },
                                            [_vm._v("+")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "sort__order__value" },
                                        [_vm._v(_vm._s(law.sortOrder))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setLawSortOrder(
                                                law,
                                                idx,
                                                -1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              staticClass:
                                                "sort__order__button",
                                              attrs: {
                                                size: "is-small",
                                                icon: "arrow-up"
                                              }
                                            },
                                            [_vm._v("-")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(law.name))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(law.lawCollectionName || ""))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(law.exerciseCategoryName || "")
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(law.status ? "פעיל" : "לא פעיל")
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 4
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { type: "is-info" },
                          on: { click: _vm.organizeVerdictsSortOrder }
                        },
                        [_vm._v("סדר מחדש")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newLessonVerdict }
                        },
                        [_vm._v("הוספת תקציר פסיקה ליחידת הלימוד")]
                      )
                    ],
                    1
                  ),
                  !_vm.isLoading && _vm.verdicts && _vm.verdicts.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "ניהול תקצירי פסיקה ביחידת הלימוד: " +
                              _vm._s(_vm.lesson.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(3),
                            _vm._l(_vm.verdicts, function(verdict, idx) {
                              return _c(
                                "div",
                                {
                                  key: idx,
                                  staticClass: "row",
                                  class: !verdict.status && "not-active"
                                },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.id))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "column-item flex" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setVerdictSortOrder(
                                                verdict,
                                                idx,
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              staticClass:
                                                "sort__order__button",
                                              attrs: {
                                                size: "is-small",
                                                icon: "arrow-down"
                                              }
                                            },
                                            [_vm._v("+")]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        { staticClass: "sort__order__value" },
                                        [_vm._v(_vm._s(verdict.sortOrder))]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.setVerdictSortOrder(
                                                verdict,
                                                idx,
                                                -1
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "b-icon",
                                            {
                                              staticClass:
                                                "sort__order__button",
                                              attrs: {
                                                size: "is-small",
                                                icon: "arrow-up"
                                              }
                                            },
                                            [_vm._v("-")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.name))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(verdict.verdictSummaryName || "")
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(verdict.lawName || ""))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(verdict.exerciseCategoryName || "")
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(
                                        verdict.status ? "פעיל" : "לא פעיל"
                                      )
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 5
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "new",
                          on: { click: _vm.newLessonBook }
                        },
                        [_vm._v("שיוך ספר ליחידת הלימוד")]
                      )
                    ]
                  ),
                  !_vm.isLoading && _vm.books && _vm.books.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "ניהול ספרים ביחידת הלימוד: " +
                              _vm._s(_vm.lesson.name) +
                              " "
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(4),
                            _vm._l(_vm.books, function(book, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(book.bookId))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(book.sortOrder))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(book.bookName))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(book.bookAuthor))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(book.status ? "פעיל" : "לא פעיל")
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" ID ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סדר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מקבץ חקיקה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מקבץ פסיקה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סטטוס ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" קטגוריה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" חומרי עזר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" הצג סרטון ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סדר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם תרגול ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" נושא ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סטטוס ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" הגדרות ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סדר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מקבץ ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" קטגוריה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סטטוס ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סדר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תקציר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" חוק ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" קטגוריה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סטטוס ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סדר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שם ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מחבר ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" סטטוס ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }