<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">עדכון קורסים לתלמיד - {{ privateName }} {{ lastName }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <div style="display: flex; gap:12px;" v-for="course in courses" :key="course.id">
                        <input type="checkbox" :name="course.id" v-model="course.isSelected" :id="course.id">
                        <label style="cursor: pointer" :for="course.id">{{ course.courseName }}</label>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import UserService from "../services/UserService";

export default {
    name: "DialogReportDriverAssign",
    props: ["userId", "privateName", "lastName", "store"],
    components: {
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
        this.getCourses();
        console.log('ss');
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            courses: null,
            model: {
                name: null,
                status: 1
            }
        };
    },
    methods: {
        getCourses() {
            UserService.getUserCourses(this.userId).then((r) => {
                this.courses = r.data;
            });
        },
        save() {
            this.isLoading = true;
            UserService.updateUserCourses(this.userId, {
                EntityIds: this.courses.filter((t) => t.isSelected).map((course) => course.id)
            })
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    this.$emit("customEvent");
                    this.$emit("onSave");
                    // window.location.reload();
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style scoped lang="scss">
.DialogReportPaid__Component__Loading {
    max-height: 600px;
    overflow: auto;
}
</style>
