<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">עדכון פרופיל</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <!-- <h1>נא להזין כתובת חדשה</h1> -->
        <div class="DialogReportPaid__Component__Loading">
          <div v-if="isLoadingDriversList">
            <b-loading :is-full-page="false" v-model="isLoadingDriversList" :can-cancel="false" />
          </div>
          <b-field label="שם חברה">
            <b-input v-model="data.Name"></b-input>
          </b-field>
          <b-field label="ח.פ">
            <b-input v-model="data.IdNumber"></b-input>
          </b-field>
          <b-field label="מייל">
            <b-input v-model="data.Email"></b-input>
          </b-field>
          <b-field label="עיר">
            <b-input v-model="data.City"></b-input>
          </b-field>
          <b-field label="רחוב">
            <b-input v-model="data.Street"></b-input>
          </b-field>
          <b-field label="מספר בית">
            <b-input v-model="data.HouseNumber"></b-input>
          </b-field>
          <b-field label="מיקוד">
            <b-input v-model="data.ZipCode"></b-input>
          </b-field>
          <b-field label="תייר">
            <b-switch class="ltr" v-model="data.IsForeignCitizen">{{
              data.IsForeignCitizen ? "כן" : "לא"
            }}</b-switch>
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
// import DriverService from "@/services/DriverService";
import ProfileService from "@/services/ProfileService";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "DialogReportDriverAssign",
  props: ["reports", "entityId", "currentDriver", "onSuccess", "profileId"],
  components: {
  },
  computed: {
    items() {
      return this.reports ?? this.entityId;
    },
  },
  created() {
    ProfileService.get({
      headers: {
        "Profile-Id": this.profileId
      }
    }).then((r) => {
      this.isLoadingDriversList = false;
      this.data = r;
      console.log(this.data);
    });
  },
  data() {
    return {
      isLoading: false,
      isLoadingDriversList: true,
      driverId: null,
      isDriverPaid: false,
      showAmount: false,
      amount: null,
      data: [],
      address: null
    };
  },
  methods: {
    setSelected(value) {
      this.driverId = value;
    },
    save() {
      this.isLoading = true;
      // const data = this.items.map((r) => ({
      //   ReportId: r,
      //   DriverId: this.driverId === 0 ? null : this.driverId,
      //   IsPaidByDriver: this.driverId && this.isDriverPaid,
      //   DriverAmount:
      //     this.isDriverPaid && this.showAmount && this.driverId
      //       ? this.amount
      //       : null,
      // }));
      ProfileService.update(this.data, {
        headers: {
          "Profile-Id": this.profileId
        }
      })
        .then(() => {
          this.$emit("close");
          if (this.onSuccess) this.onSuccess();
          Toast.open({
            type: "is-success",
            message: "הפעולה בוצעה!",
            duration: 4000,
          });
        })
        .catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
            duration: 8000,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss"></style>
