import { render, staticRenderFns } from "./RequestAdminsWidget.vue?vue&type=template&id=ae259cd2&scoped=true&"
import script from "./RequestAdminsWidget.vue?vue&type=script&lang=js&"
export * from "./RequestAdminsWidget.vue?vue&type=script&lang=js&"
import style0 from "./RequestAdminsWidget.vue?vue&type=style&index=0&id=ae259cd2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae259cd2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.Simulator\\Nevo.Simulator.Admin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ae259cd2')) {
      api.createRecord('ae259cd2', component.options)
    } else {
      api.reload('ae259cd2', component.options)
    }
    module.hot.accept("./RequestAdminsWidget.vue?vue&type=template&id=ae259cd2&scoped=true&", function () {
      api.rerender('ae259cd2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Widgets/RequestAdminsWidget.vue"
export default component.exports