import { render, staticRenderFns } from "./GroupStudentsWidget.vue?vue&type=template&id=11e5df5b&scoped=true&"
import script from "./GroupStudentsWidget.vue?vue&type=script&lang=js&"
export * from "./GroupStudentsWidget.vue?vue&type=script&lang=js&"
import style0 from "./GroupStudentsWidget.vue?vue&type=style&index=0&id=11e5df5b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e5df5b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Mobaxe\\Nevo.Simulator\\Nevo.Simulator.Admin.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11e5df5b')) {
      api.createRecord('11e5df5b', component.options)
    } else {
      api.reload('11e5df5b', component.options)
    }
    module.hot.accept("./GroupStudentsWidget.vue?vue&type=template&id=11e5df5b&scoped=true&", function () {
      api.rerender('11e5df5b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Widgets/GroupStudentsWidget.vue"
export default component.exports