<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadLessons()" :class="{ active: tab === 1 }">
        יחידות לימוד
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadExercises()" :class="{ active: tab === 2 }">
        תלמידים
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadLaws()" :class="{ active: tab === 3 }">
        מלווים
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadVerdicts()" :class="{ active: tab === 4 }">
        תרגולים
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" />
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <div class="Activity__Component__TabsContent__Tasks">
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="newLessonToGroup">שיוך יחידות לימוד לקבוצה</button>
          </div>
          <div v-if="videos && videos.length" class="Activity__Component__TabsContent__Tasks__Content">
            <div class="section-container">
              <h1>יחידות לימוד בקבוצה: {{ group.name }} </h1>
              <div class="document-table">
                <div class="columns">
                  <div class="column">
                    ID
                  </div>
                  <div class="column">
                    שם
                  </div>
                  <div class="column">
                    מחיקת יחידת לימוד
                  </div>
                </div>
                <div class="row" v-for="(video, idx) in videos" :key="idx">
                  <div class="column-item">{{ video.id }}</div>
                  <div class="column-item">{{ video.name }}</div>
                  <div>
                    <b-tooltip type="is-white" label="צפייה מקדימה">
                      <a style="color: red;" @click="deleteLessonInGroup(video)" class="updateItem">
                        <b-icon icon="delete"> </b-icon>
                      </a>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="newStudentsToGroup">שיוך תלמידים לקבוצה</button>
            <b-button @click="assignGroupStudentsToTrack" type="is-info">רישום חברי הקבוצה למסלול</b-button>
            <b-button :disabled="!exercises.length" @click="unAssignGroupStudentsFromTrack" type="is-danger">גריעת חברי הקבוצה ממסלול</b-button>
          </div>
          <div v-if="!isLoading && exercises && exercises.length" class="section-container">
            <h1>רשימת תלמידים בקבוצה: {{ group.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  אימייל
                </div>
                <div class="column">
                  שם תלמיד
                </div>
                <div class="column">
                  מחיקת תלמיד
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(exercise, idx) in exercises" :key="idx">
                <div class="column-item">{{ exercise.id }}</div>
                <div class="column-item">{{ exercise.userName }}</div>
                <div class="column-item">{{ exercise.privateName }} {{ exercise.lastName }}</div>
                <div @click="removeStudentFromGroup(exercise)">
                  <b-tooltip type="is-white" label="מחיקה">
                    <a style="color: red;" class="updateItem">
                      <b-icon icon="delete"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 3">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <button class="new" @click="newEscortsToGroup">שיוך מלווים לקבוצה</button>
          </div>
          <div v-if="!isLoading && laws && laws.length" class="section-container">
            <h1>רשימת מלווים בקבוצה: {{ group.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  אימייל
                </div>
                <div class="column">
                  שם
                </div>
                <div class="column">
                  מחיקת מלווה
                </div>
              </div>
              <div class="row" v-for="(law, idx) in laws" :key="idx">
                <div class="column-item">{{ law.id }}</div>
                <div class="column-item">{{ law.userName }}</div>
                <div class="column-item">{{ law.privateName }} {{ law.lastName }}</div>
                <div @click="removeEscortFromGroup(law)">
                  <b-tooltip type="is-white" label="מחיקה">
                    <a style="color: red;" class="updateItem">
                      <b-icon icon="delete"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 4">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <!-- <b-button @click="organizeVerdictsSortOrder" type="is-info">סדר מחדש</b-button>
            <button class="new" @click="newLessonVerdict">הוספת תקציר פסיקה ליחידת הלימוד</button> -->
          </div>
          <div v-if="!isLoading && verdicts && verdicts.length" class="section-container">
            <h1>רשימת תרגולים בקבוצה: {{ group.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  שם תלמיד
                </div>
                <div class="column">
                  שם תרגול
                </div>
                <div class="column">
                  קישור לדוח
                </div>
                <div class="column">
                  טווח ציונים תקין
                </div>
                <div class="column">
                  אחוז כוכביות
                </div>
                <div class="column">
                  ציון כוכביות
                </div>
                <div class="column">
                  אחוז אפורות
                </div>
                <div class="column">
                  ציון אפורות
                </div>
                <div class="column">
                  תאריך ביצוע
                </div>
                <div class="column">
                  פתרון להדפסה
                </div>
                <div class="column">
                  שאלון להדפסה
                </div>
              </div>
              <div class="row" v-for="(verdict, idx) in verdicts" :key="idx">
                <div class="column-item">{{ verdict.userName }}</div>
                <div class="column-item">{{ verdict.exerciseName }}</div>
                <div class="column-item">
                  <a :href="verdict.reportUrl">קישור לתרגול</a>
                </div>
                <div class="column-item">{{ verdict.grade }}</div>
                <div class="column-item">{{ verdict.starPercent }}</div>
                <div class="column-item">{{ verdict.starGrade }}</div>
                <div class="column-item">{{ verdict.grayPercent }}</div>
                <div class="column-item">{{ verdict.grayGrade }}</div>
                <div class="column-item">{{ parseDate(verdict.startDateTime) }}</div>
                <div class="column-item">
                  <router-link target="_blank"
                    :to="{ name: 'ExercisePrint', params: { courseId: course.id, exerciseId: verdict.exerciseId }, query: { showAnswers: true } }">פתרון</router-link>
                </div>
                <div class="column-item">
                  <router-link target="_blank"
                    :to="{ name: 'ExercisePrint', params: { courseId: course.id, exerciseId: verdict.exerciseId } }">שאלון</router-link>

                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import ActivityItem from "@/components/ActivityItem.vue";
import moment from "moment";
// import { ContentLoader } from "vue-content-loader";
import EntityItemsService from "@/services/EntityItemsService";
import DialogVideoPreview from "@/components/DialogVideoPreview.vue";
import AssignGroupsToLessons from '@/components/AssignGroupsToLessons.vue';
import AssignGroupsToStudents from '@/components/AssignGroupsToStudents.vue';
import DialogAssignGroupStudentsToTrack from '@/components/DialogAssignGroupStudentsToTrack.vue';
import DialogUnAssignGroupStudentsFromTrack from '@/components/DialogUnAssignGroupStudentsFromTrack.vue';
// import UserService from "@/services/UserService";
import _ from "lodash";
import { ModalProgrammatic as Modal } from "buefy";
import NewActivity from "./NewActivity.vue";
import DialogAddExercise from "./DialogAddExercise.vue";
import DialogAddLaw from "./DialogAddLaw.vue";
import DialogAddLessonVideo from "./DialogAddLessonVideo.vue";
import LessonService from "../services/LessonService";
import DialogAddVerdict from "./DialogAddVerdict.vue";
import CoursesService from "../services/CoursesService";

export default {
  name: "GroupEntityPage",
  components: {
    // ActivityItem,
    // ContentLoader,
    NewActivity,
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    lesson() {
      return this.$store.state.lesson;
    },
    group() {
      return this.$store.state.group;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isLoading: false,
      videos: null,
      exercises: null,
      laws: null,
      verdicts: null
    };
  },
  created() {
    this.loadLessons();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    deleteLessonInGroup(lesson) {
      this.isLoading = true;
      LessonService.deleteLessonInGroup(this.group.id, lesson.id)
        .then(() => {
          this.loadLessons();
        }).catch(() => {
          console.log('no!');
        }).finally(() => {
          this.isLoading = false;
        });
    },
    removeStudentFromGroup(student) {
      this.isLoading = true;
      LessonService.deleteStudentInGroup(this.group.id, student.id)
        .then(() => {
          this.loadExercises();
        }).catch(() => {
          console.log('no!');
        }).finally(() => {
          this.isLoading = false;
        });
    },
    removeEscortFromGroup(escort) {
      this.isLoading = true;
      LessonService.deleteEscortInGroup(this.group.id, escort.id)
        .then(() => {
          this.loadLaws();
        }).catch(() => {
          console.log('no!');
        }).finally(() => {
          this.isLoading = false;
        });
    },
    previewVideo(link) {
      Modal.open({
        component: DialogVideoPreview,
        customClass: "entity-modal",
        props: {
          link: link
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    saveTask(data) {
      this.$refs.taskActivity.setLoading(true);
      EntityItemsService.addTask(this.entityType, this.entityId, data)
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewTask = false;
        })
        .catch(() => {
          this.$refs.taskActivity.setLoading(false);
        })
        .finally(() => { });
    },
    setVideoSortOrder(currVideo, idx, action) {
      const currentVideo = currVideo;
      const videoToChange = this.videos[idx + action];
      if (!videoToChange) return;
      if (action === 1) {
        videoToChange.sortOrder = currentVideo.sortOrder;
        currentVideo.sortOrder += 1;
      } else {
        videoToChange.sortOrder = currentVideo.sortOrder;
        currentVideo.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentVideo.id,
          sortOrder: currentVideo.sortOrder
        },
        itemToChange: {
          id: videoToChange.id,
          sortOrder: videoToChange.sortOrder
        }
      }
      this.isLoading = true;
      LessonService.setLessonVideosSortOrder(model)
        .then(() => {
          this.loadLessons();
          this.isLoading = false;
        });
    },
    organizeVideosSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonVideos(this.videos)
        .then(() => {
          this.loadLessons();
          this.isLoading = false;
        });
    },
    setExerciseSortOrder(currExercise, idx, action) {
      const currentExercise = currExercise;
      const exerciseToChange = this.exercises[idx + action];
      if (!exerciseToChange) return;
      if (action === 1) {
        exerciseToChange.sortOrder = currentExercise.sortOrder;
        currentExercise.sortOrder += 1;
      } else {
        exerciseToChange.sortOrder = currentExercise.sortOrder;
        currentExercise.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentExercise.id,
          sortOrder: currentExercise.sortOrder
        },
        itemToChange: {
          id: exerciseToChange.id,
          sortOrder: exerciseToChange.sortOrder
        }
      }
      this.isLoading = true;
      LessonService.setLessonExercisesSortOrder(model)
        .then(() => {
          this.loadExercises();
          this.isLoading = false;
        });
    },
    organizeExercisesSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonExercises(this.exercises)
        .then(() => {
          this.loadExercises();
          this.isLoading = false;
        });
    },
    setLawSortOrder(currLaw, idx, action) {
      const currentLaw = currLaw;
      const lawToChange = this.laws[idx + action];
      if (!lawToChange) return;
      if (action === 1) {
        lawToChange.sortOrder = currentLaw.sortOrder;
        currentLaw.sortOrder += 1;
      } else {
        lawToChange.sortOrder = currentLaw.sortOrder;
        currentLaw.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentLaw.id,
          sortOrder: currentLaw.sortOrder,
          lawCollectionId: currentLaw.lawCollectionId,
          exerciseCategoryId: currentLaw.exerciseCategoryId,
          lessonId: currentLaw.lessonId
        },
        itemToChange: {
          id: lawToChange.id,
          sortOrder: lawToChange.sortOrder,
          lawCollectionId: lawToChange.lawCollectionId,
          exerciseCategoryId: lawToChange.exerciseCategoryId,
          lessonId: lawToChange.lessonId
        }
      }
      this.isLoading = true;
      LessonService.setLessonLawsCollectionSortOrder(model)
        .then(() => {
          this.loadLaws();
          this.isLoading = false;
        });
    },
    organizeLawsSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonLawsCollection(this.laws)
        .then(() => {
          this.loadLaws();
          this.isLoading = false;
        });
    },
    setVerdictSortOrder(currVerdict, idx, action) {
      const currentVerdict = currVerdict;
      const verdictToChange = this.verdicts[idx + action];
      if (!verdictToChange) return;
      if (action === 1) {
        verdictToChange.sortOrder = currentVerdict.sortOrder;
        currentVerdict.sortOrder += 1;
      } else {
        verdictToChange.sortOrder = currentVerdict.sortOrder;
        currentVerdict.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentVerdict.id,
          sortOrder: currentVerdict.sortOrder,
          verdictSummaryId: currentVerdict.verdictSummaryId,
          lessonId: currentVerdict.lessonId
        },
        itemToChange: {
          id: verdictToChange.id,
          sortOrder: verdictToChange.sortOrder,
          verdictSummaryId: verdictToChange.verdictSummaryId,
          lessonId: verdictToChange.lessonId
        }
      }
      this.isLoading = true;
      LessonService.setLessonVerdictsCollectionSortOrder(model)
        .then(() => {
          this.loadVerdicts();
          this.isLoading = false;
        });
    },
    organizeVerdictsSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonVerdictsCollection(this.verdicts)
        .then(() => {
          this.loadVerdicts();
          this.isLoading = false;
        });
    },
    saveNote(data) {
      this.$refs.noteActivity.setLoading(true);
      EntityItemsService.addNote(this.entityType, this.entityId, {
        Body: data.Body,
      })
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewNote = false;
        })
        .finally(() => {
          this.$refs.noteActivity.setLoading(false);
        });
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    newLessonExercise() {
      Modal.open({
        component: DialogAddExercise,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonLaw() {
      Modal.open({
        component: DialogAddLaw,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    goToExercise(id) {
      this.$router.replace(`exercise/${id}`)
    },
    newLessonVerdict() {
      Modal.open({
        component: DialogAddVerdict,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: this.entityId
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonToGroup() {
      Modal.open({
        component: AssignGroupsToLessons,
        props: {
          groups: [this.entityId],
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    newStudentsToGroup() {
      Modal.open({
        component: AssignGroupsToStudents,
        props: {
          groups: [this.entityId],
          store: this.$store
        },
        events: {
          customEvent: () => {
            this.loadExercises();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    assignGroupStudentsToTrack() {
      Modal.open({
        component: DialogAssignGroupStudentsToTrack,
        props: {
          groupId: this.entityId,
          students: this.exercises,
          store: this.$store,
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    unAssignGroupStudentsFromTrack() {
      Modal.open({
        component: DialogUnAssignGroupStudentsFromTrack,
        props: {
          groupId: this.entityId,
          students: this.exercises,
          store: this.$store,
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    newEscortsToGroup() {
      Modal.open({
        component: AssignGroupsToStudents,
        props: {
          groups: [this.entityId],
          store: this.$store,
          isEscort: true
        },
        events: {
          customEvent: () => {
            this.loadLaws();
          }
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    loadLessons() {
      this.isLoading = true;
      CoursesService.getCourse(
        this.course.id,
        {
          GroupIds: [this.entityId],
          PageSize: 999,
          PageNumber: 1
        },
        false,
        null).then((r) => {
          this.videos = r.data.items;
        })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    loadExercises() {
      this.isLoading = true;
      CoursesService.getUsersByCourse(
        this.$store.state.auth.course?.id,
        {
          GroupIds: [this.entityId],
          PageSize: 999,
          PageNumber: 1
        },
        false,
        null)
        .then((r) => {
          this.exercises = r.data.items;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadLaws() {
      this.isLoading = true;
      CoursesService.getUsersByCourse(
        this.course.id,
        {
          GroupIds: [this.entityId],
          GroupEscort: true,
          PageSize: 999,
          PageNumber: 1
        },
        false,
        null)
        .then((r) => {
          this.laws = r.data.items;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      LessonService.getExerciseAnswers(
        this.$store.state.auth.course?.id,
        {
          GroupIds: [this.entityId],
          PageSize: 999,
          PageNumber: 1
        },
        false,
        null)
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eef1f3;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.flex {
  display: flex !important;
}

.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

.sort__order__button {
  padding: 5px;
  background-color: #1eb2d0;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.sort__order__value {
  width: 15px;
  margin: 0 10px;
  display: inline-block;
  text-align: center;
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
