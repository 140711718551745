<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div v-if="lessonVideos && lessonVideos.length" class="StudentGroups__Container">
      <div :class="[video.isSelected && 'green']" class="StudentGroupsWidget__Component" v-for="video in lessonVideos"
        :key="video.id">
        <p>{{ video.name }}</p>
      </div>
    </div>
    <div v-if="lessonVideos && lessonVideos.length" class="link-btn">
      <a @click="showAll">הצג הכל</a>
    </div>
    <div v-if="!loading && !lessonVideos.length">אין סרטונים משויכים ליחידת הלימוד</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import DialogUpdateProfile from "../DialogUpdateProfile.vue";
import LessonService from "../../services/LessonService";
// import LeasingCompaniesService from "../../services/LeasingCompaniesService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch", "query"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      lessonVideos: null,
      lessonVideosCount: null,
      loading: false
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id"]),
    };
  },
  created() {
    // if (this.id) {
    //   LessonService.getUservideos(this.id)
    //     .then((res) => {
    //       this.lessonVideos = res.data;
    //     });
    // }
  },
  computed: {
  },
  methods: {
    showAll() {
      this.$router.push({
        name: "Videos",
        params: {
          LessonIds: [this.id]
        },
      });
    },
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    openModal() {
      Modal.open({
        component: DialogUpdateProfile,
        customClass: "entity-modal",
        props: {
          profileId: this.clientId,
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    init() {
      if (this.id) {
        this.loading = true;
        LessonService.getAdminVideos(this.$store.state.auth.course?.id, {
          LessonIds: [this.id],
          PageSize: 5
        })
          .then((res) => {
            this.lessonVideos = res.data.items;
            this.lessonVideosCount = res.data.totalResults;
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.lessonVideosCount });
            this.loading = false;
          });
        this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StudentGroups__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.StudentGroupsWidget__Component {
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.green {
  background-color: #dff0d8;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
