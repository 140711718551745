var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            "עדכון מסלולים לתלמיד - " +
              _vm._s(_vm.privateName) +
              " " +
              _vm._s(_vm.lastName)
          )
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogReportPaid__Component__Loading" },
          _vm._l(_vm.tracks, function(track) {
            return _c(
              "div",
              { key: track.id, staticStyle: { display: "flex", gap: "12px" } },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: track.isSelected,
                      expression: "track.isSelected"
                    }
                  ],
                  attrs: { type: "checkbox", name: track.id, id: track.id },
                  domProps: {
                    checked: Array.isArray(track.isSelected)
                      ? _vm._i(track.isSelected, null) > -1
                      : track.isSelected
                  },
                  on: {
                    change: function($event) {
                      var $$a = track.isSelected,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(track, "isSelected", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              track,
                              "isSelected",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(track, "isSelected", $$c)
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: { for: track.id }
                  },
                  [_vm._v(_vm._s(track.trackName))]
                )
              ]
            )
          }),
          0
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }