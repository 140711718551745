<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">יצירת תרגול חדש מתרגול חשיפה</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <b-field label="הזן שם לתרגול">
                        <b-input v-model="model.ExerciseName"></b-input>
                    </b-field>
                    <b-field label="יחידת לימוד">
                        <FieldInlineSelect label="" :filterable="true" :clearable="true" :optionsAsync="getLessons"
                            v-model="model.ToLessonId" />
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineText from "./Fields/FieldInlineText.vue";
import FieldInlineSelect from "./Fields/FieldInlineSelect.vue";
import CoursesService from '../services/CoursesService';

export default {
    name: "DialogReportDriverAssign",
    props: ["store", "router"],
    components: {
        FieldInlineText,
        FieldInlineSelect
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id;
        },
        expoChapterId() {
            return this.store.state.expoChapter.id;
        }
    },
    data() {
        return {
            isLoading: false,
            model: {
                ExerciseName: null,
                ToLessonId: null
            },
        };
    },
    methods: {
        save() {
            if (this.model.ExerciseName && this.model.ToLessonId) {
                this.isLoading = true;
                this.model.FromExpoChapterId = this.expoChapterId;
                CoursesService.createExerciseFromExpo({
                    ...this.model
                })
                    .then((r) => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        this.router.replace(`/exercise/${r.data}`);
                        // window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        getLessons() {
            return () =>
                CoursesService.getCourse(this.courseId, {
                    PageSize: 999,
                    IsAscending: true,
                    PageNumber: 1,
                    SortedBy: "id",
                    ResultType: 3
                }, true).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: `${i.id} - ${i.name}` }))
                );
        },
    },
};
</script>

<style scoped lang="scss">
.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.items {
    flex-direction: column;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
    cursor: pointer;
}

.items {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
        flex: 1;
    }

    input {
        width: 50px;
    }
}

.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
}

.clicked {
    background-color: gray;
}
</style>
