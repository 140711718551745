var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Activity__Component" }, [
    _c("div", { staticClass: "Activity__Component__Tabs" }, [
      _c(
        "div",
        {
          staticClass: "Activity__Component__Tabs__Item",
          class: { active: _vm.tab === 1 },
          on: {
            click: function($event) {
              return _vm.loadExpoQuestions()
            }
          }
        },
        [_vm._v(" שאלות בפרק ")]
      )
    ]),
    _c(
      "div",
      { staticClass: "Activity__Component__TabsContent" },
      [
        _vm.isLoading
          ? [
              _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("b-loading", {
                    attrs: { "is-full-page": false, "can-cancel": false },
                    model: {
                      value: _vm.isLoading,
                      callback: function($$v) {
                        _vm.isLoading = $$v
                      },
                      expression: "isLoading"
                    }
                  })
                ],
                1
              )
            ]
          : _vm._e(),
        _vm.tab === 1
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button-close",
                          on: { click: _vm.createExerciseFromExpo }
                        },
                        [_vm._v("צור כתרגול רב ברירה")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "link-button",
                          on: { click: _vm.addExpoChapterQuestion }
                        },
                        [_vm._v("הוספת שאלות חשיפה לפרק")]
                      ),
                      _c(
                        "b-dropdown",
                        {
                          attrs: { "aria-role": "list" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "trigger",
                                fn: function(ref) {
                                  var active = ref.active
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "Entity__Details__PageOptions__Actions"
                                      },
                                      [
                                        _c("span", [_vm._v("פעולות")]),
                                        _c("b-icon", {
                                          attrs: {
                                            icon: active
                                              ? "menu-up"
                                              : "menu-down"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1070624676
                          )
                        },
                        [
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "dropdown-iteam-to-right",
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.exportQuestions }
                            },
                            [_vm._v("יצוא טבלה")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "dropdown-iteam-to-right",
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.calculateUnitDisplay }
                            },
                            [_vm._v("סדר תווית שאלות שאלות פעילות מחדש")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "dropdown-iteam-to-right",
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.deleteQuestions }
                            },
                            [_vm._v("מחיקת כל שאלות הפרק")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              staticClass: "dropdown-iteam-to-right",
                              attrs: { "aria-role": "listitem" },
                              on: { click: _vm.showStatistics }
                            },
                            [_vm._v("הצג/הסתר סטטיסטיקה")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.isLoading && _vm.exercises && _vm.exercises.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.exercises.length) +
                              " שאלות בפרק חשיפה " +
                              _vm._s(_vm.expoChapter.displayName)
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(0),
                            _vm._l(_vm.exercises, function(exercise, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.id))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.unitDisplayName))
                                  ]),
                                  _c("div", {
                                    staticClass: "column-item",
                                    domProps: {
                                      innerHTML: _vm._s(exercise.question)
                                    }
                                  }),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(exercise.answer ? "כן" : "לא")
                                    )
                                  ]),
                                  _c("div", {
                                    staticClass: "column-item",
                                    domProps: {
                                      innerHTML: _vm._s(exercise.detailedAnswer)
                                    }
                                  }),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(exercise.status ? "כן" : "לא")
                                    )
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.count))
                                  ]),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(exercise.percent))
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.updateQuestion(exercise)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-white",
                                            label: "ערוך שאלת חשיפה"
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "updateItem",
                                              staticStyle: { color: "#1eb2d0" }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "pen" }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e(),
        _vm.tab === 2
          ? _c(
              "div",
              [
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "Activity__Component__TabsContent__Tasks__Header"
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "new", on: { click: _vm.newRemark } },
                        [_vm._v("הוספת הערה")]
                      )
                    ]
                  ),
                  !_vm.isLoading &&
                  _vm.studentRemarks &&
                  _vm.studentRemarks.length
                    ? _c("div", { staticClass: "section-container" }, [
                        _c("h1", [
                          _vm._v(
                            "יומן מלווה לתלמיד " +
                              _vm._s(_vm.userName) +
                              " בקורס " +
                              _vm._s(_vm.course.name)
                          )
                        ]),
                        _c(
                          "div",
                          { staticClass: "document-table" },
                          [
                            _vm._m(1),
                            _vm._l(_vm.studentRemarks, function(remark, idx) {
                              return _c(
                                "div",
                                { key: idx, staticClass: "row" },
                                [
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(_vm._s(remark.byUserName))
                                  ]),
                                  _c("div", {
                                    staticClass: "column-item",
                                    domProps: {
                                      innerHTML: _vm._s(remark.contentToDisplay)
                                    }
                                  }),
                                  _c("div", { staticClass: "column-item" }, [
                                    _vm._v(
                                      _vm._s(_vm.parseDate(remark.dateCreated))
                                    )
                                  ])
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" מזהה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תווית יחידה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" שאלה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תשובה נכונה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" פתרון ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" פעיל ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מספר עונים ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" מענה נכונה ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" עריכה ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [_vm._v(" על ידי ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" נושא ")]),
      _c("div", { staticClass: "column" }, [_vm._v(" תאריך ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }