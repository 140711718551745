var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Entity", class: { "Entity-Preview": _vm.preview } },
    [
      _c("div", { staticClass: "Entity__Details" }, [
        _c("div", { staticClass: "Entity__Details__PageOptions" }, [
          !_vm.preview
            ? _c(
                "div",
                {
                  staticClass: "Entity__Details__PageOptions__Back",
                  on: {
                    click: function($event) {
                      return _vm.back()
                    }
                  }
                },
                [_vm._m(0), _vm._v(" " + _vm._s(_vm.backButtonText) + " ")]
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "Entity__Details__BaseDetails" },
          [
            _vm.entityReady
              ? [
                  _c(
                    "div",
                    { staticClass: "Entity__Details__BaseDetails__Title" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "Entity__Details__BaseDetails__Title__Text"
                        },
                        [_vm._v(_vm._s(_vm.title))]
                      )
                    ]
                  ),
                  _vm._t("mandatory")
                ]
              : _vm._e(),
            !_vm.entityReady
              ? [
                  _c(
                    "content-loader",
                    {
                      attrs: {
                        width: 340,
                        height: 84,
                        speed: 2,
                        primaryColor: "#f3f3f3",
                        secondaryColor: "#ecebeb"
                      }
                    },
                    [
                      _c("rect", {
                        attrs: {
                          x: "118",
                          y: "10",
                          rx: "3",
                          ry: "3",
                          width: "67",
                          height: "11"
                        }
                      }),
                      _c("rect", {
                        attrs: {
                          x: "196",
                          y: "10",
                          rx: "3",
                          ry: "3",
                          width: "140",
                          height: "11"
                        }
                      }),
                      _c("rect", {
                        attrs: {
                          x: "24",
                          y: "84",
                          rx: "3",
                          ry: "3",
                          width: "460",
                          height: "137"
                        }
                      }),
                      _c("rect", {
                        attrs: {
                          x: "22",
                          y: "30",
                          rx: "3",
                          ry: "3",
                          width: "140",
                          height: "11"
                        }
                      }),
                      _c("rect", {
                        attrs: {
                          x: "170",
                          y: "31",
                          rx: "3",
                          ry: "3",
                          width: "173",
                          height: "11"
                        }
                      })
                    ]
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "Entity__Details__BaseDetails__About" },
          [
            _c("Accordion", {
              attrs: { "open-on-start": true, loading: !_vm.entityReady },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function() {
                    return [_vm._v(" " + _vm._s(_vm.innerComponentTitle))]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _vm.entityReady
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "Entity__Details__BaseDetails__About__Content"
                            },
                            [
                              _c(_vm.innerComponent, {
                                ref: "form",
                                tag: "component",
                                attrs: {
                                  readOnly: _vm.readOnly,
                                  "entity-id": _vm.entityId,
                                  OnProps: _vm.OnProps
                                },
                                on: { onFormChange: _vm.onFormChange }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        )
      ]),
      !_vm.preview
        ? _c(
            "div",
            { staticClass: "Entity__Activity" },
            [
              _vm.entityType === "lesson"
                ? _c("Activity", {
                    ref: "activity",
                    attrs: {
                      activities: _vm.activities,
                      loading: _vm.loadingActivities,
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e(),
              _vm.entityType === "exercise"
                ? _c("ExerciseForm", {
                    ref: "exerciseForm",
                    attrs: {
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e(),
              _vm.entityType === "question"
                ? _c("QuestionForm", {
                    ref: "questionForm",
                    attrs: {
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e(),
              _vm.entityType === "user"
                ? _c("UserExercises", {
                    ref: "userExercises",
                    attrs: {
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e(),
              _vm.entityType === "expoChapter"
                ? _c("ExpoChapterPage", {
                    ref: "expoChapter",
                    attrs: {
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e(),
              _vm.entityType === "group"
                ? _c("GroupEntityPage", {
                    ref: "expoChapter",
                    attrs: {
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e(),
              _vm.entityType === "lawCollection"
                ? _c("LawCollectionEntityPage", {
                    ref: "lawCollection",
                    attrs: {
                      entityType: _vm.entityType,
                      entityId: _vm.entityId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      !_vm.isEntityPage || (_vm.isEntityPage && _vm.showRelations)
        ? _c(
            "div",
            { staticClass: "Entity__Relations" },
            _vm._l(_vm.widgetsList, function(widget, index) {
              return _c("div", { key: index }, [
                !widget.isHidden
                  ? _c(
                      "div",
                      { staticClass: "Entity__Relations__Item" },
                      [
                        _c("Accordion", {
                          attrs: {
                            "open-on-start": index === 0 || widget.open,
                            loading: !widget.ready || !_vm.entityReady,
                            "loading-type": widget.loadingType
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function() {
                                  return [
                                    _c("span", [
                                      _vm._v(" " + _vm._s(widget.title) + " "),
                                      widget.multiple
                                        ? _c("span", [
                                            _vm._v(
                                              "(" +
                                                _vm._s(widget.titleCount) +
                                                ")"
                                            )
                                          ])
                                        : _vm._e()
                                    ]),
                                    widget.editComponent && !_vm.preview
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "Entity__Relations__Item__Edit",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.setSideBarContent(
                                                  widget
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" עריכה ")]
                                        )
                                      : _vm._e(),
                                    !widget.addable &&
                                    widget.modal &&
                                    !_vm.preview &&
                                    (!widget.isAdminEditable || _vm.isAdmin)
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "Entity__Relations__Item__Edit",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showModal(widget)
                                              }
                                            }
                                          },
                                          [_vm._v(" ערוך ")]
                                        )
                                      : _vm._e(),
                                    widget.addable &&
                                    !_vm.preview &&
                                    (!widget.isAdminEditable || _vm.isAdmin)
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "Entity__Relations__Item__Edit",
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.showModal(widget)
                                              }
                                            }
                                          },
                                          [_vm._v(" ערוך ")]
                                        )
                                      : _vm._e()
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "content",
                                fn: function() {
                                  return [
                                    _c(widget.type, {
                                      tag: "component",
                                      attrs: {
                                        widget: widget,
                                        namespace: widget.namespace,
                                        field: widget.field,
                                        ready: _vm.entityReady,
                                        fetch: widget.fetch,
                                        delegate: widget.delegate,
                                        query: widget.query
                                      },
                                      on: {
                                        onReady: function(data) {
                                          return _vm.onWidgetReady(widget, data)
                                        }
                                      }
                                    })
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        : _vm._e(),
      _c("EntitySaveDialog", {
        attrs: {
          open: _vm.saveDialog,
          "is-loading": _vm.isSaving,
          amount: _vm.changesAmount
        },
        on: { onSave: _vm.onFormSave, onCancel: _vm.onFormDiscard }
      }),
      _vm.isSideBarComponentOpen
        ? _c(
            "SideMenu",
            { attrs: { width: "420px" } },
            [
              _c(_vm.sideBarComponent.component, {
                tag: "component",
                on: {
                  close: function($event) {
                    _vm.isSideBarComponentOpen = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("i", [
      _c("img", { attrs: { src: require("@/assets/down-arrow.svg"), alt: "" } })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }