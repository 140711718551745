<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">גריעת תלמידים בקבוצה ממסלול</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogReportPaid__Component__Loading">
                    <FieldInlineSelect :hide-hover="true" label="בחירת מסלול" :clearable="false" :filterable="false"
                        :optionsAsync="getTracks" @onChange="onChange" v-model="trackId" />
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { ToastProgrammatic as Toast } from "buefy";
import CoursesService from "../services/CoursesService";
import LessonService from "../services/LessonService";

export default {
    name: "DialogReportDriverAssign",
    props: ["groupId", "students", "store"],
    components: {
        FieldInlineSelect,
    },
    data() {
        return {
            isLoading: false,
            trackId: null,
        };
    },
    computed: {
        courseId() {
            return this.store.state.auth.course.id
        }
    },
    methods: {
        save() {
            if (this.trackId) {
                console.log(this.groupId, this.trackId, this.students);
                this.isLoading = true;
                LessonService.unAssignGroupStudentsToTrack(this.groupId, this.trackId)
                    .then(() => {
                        this.$emit("close");
                        // window.location.reload();
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { },
        getTracks() {
            return () =>
                CoursesService.getCourseLearningTracks(this.courseId, {
                    PageNumber: 1,
                    PageSize: 999
                }).then((r) =>
                    r.data.items.map((i) => ({ Value: i.id, Text: i.name }))
                );
        },
    },
};
</script>

<style lang="scss"></style>
