<template>
  <div class="Activity__Component">
    <div class="Activity__Component__Tabs">
      <div class="Activity__Component__Tabs__Item" @click="loadVideos()" :class="{ active: tab === 1 }">
        וידאו
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadExercises()" :class="{ active: tab === 2 }">
        תרגולים
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadLaws()" :class="{ active: tab === 3 }">
        מקבצי חקיקה
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadVerdicts()" :class="{ active: tab === 4 }">
        תקצירי פסיקה
      </div>
      <div class="Activity__Component__Tabs__Item" @click="loadBooks()" :class="{ active: tab === 5 }">
        ספרים
      </div>
    </div>
    <div class="Activity__Component__TabsContent">
      <new-activity ref="noteActivity" v-if="showNewNote" type="Note" @onClose="close" @onSave="saveNote" />
      <new-activity ref="taskActivity" v-if="showNewTask" type="Task" show-title="true" @onClose="closeTask"
        @onSave="saveTask" />
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
      <div v-if="tab === 1">
        <div class="Activity__Component__TabsContent__Tasks">
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <b-button @click="organizeVideosSortOrder" type="is-info">סדר מחדש</b-button>
            <button class="new" @click="newLessonVideo">הוספת וידאו ליחידת הלימוד</button>
          </div>
          <div v-if="videos && videos.length" class="Activity__Component__TabsContent__Tasks__Content">
            <div class="section-container">
              <h1>ניהול וידאו ביחידת הלימוד: {{ lesson.name }} </h1>
              <div class="document-table">
                <div class="columns">
                  <div class="column">
                    ID
                  </div>
                  <div class="column">
                    סדר
                  </div>
                  <div class="column">
                    שם
                  </div>
                  <div class="column">
                    מקבץ חקיקה
                  </div>
                  <div class="column">
                    מקבץ פסיקה
                  </div>
                  <div class="column">
                    סטטוס
                  </div>
                  <div class="column">
                    קטגוריה
                  </div>
                  <div class="column">
                    חומרי עזר
                  </div>
                  <div class="column">
                    הצג סרטון
                  </div>
                  <!-- <div class="column" style="width: 50px"></div> -->
                </div>
                <div :class="!video.status && 'not-active'" class="row" v-for="(video, idx) in videos" :key="idx">
                  <div class="column-item">{{ video.id }}</div>
                  <div class="column-item flex">
                    <span @click="setVideoSortOrder(video, idx, 1)"><b-icon size="is-small" icon="arrow-down"
                        class="sort__order__button">+</b-icon></span>
                    <span class="sort__order__value">{{ video.sortOrder }}</span>
                    <span @click="setVideoSortOrder(video, idx, -1)"><b-icon size="is-small" icon="arrow-up"
                        class="sort__order__button">-</b-icon></span>
                  </div>
                  <div class="column-item">{{ video.name }}</div>
                  <div class="column-item">{{ video.lawCollectionName }}</div>
                  <div class="column-item">{{ video.verdictCollectionName }}</div>
                  <div class="column-item">{{ video.status ? 'פעיל' : 'לא פעיל' }}</div>
                  <div class="column-item">{{ video.categoryName }}</div>
                  <div v-html="video.supportingMaterials" class="column-item"></div>
                  <div>
                    <b-tooltip type="is-white" label="צפייה מקדימה">
                      <a @click="previewVideo(video.link)" class="updateItem">
                        <b-icon icon="eye"> </b-icon>
                      </a>
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="tab === 2">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <b-button @click="organizeExercisesSortOrder" type="is-info">סדר מחדש</b-button>
            <button class="new" @click="newLessonExercise">הוספת תרגול ליחידת הלימוד</button>
          </div>
          <div v-if="!isLoading && exercises && exercises.length" class="section-container">
            <h1>ניהול תרגולים ביחידת הלימוד: {{ lesson.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  סדר
                </div>
                <div class="column">
                  שם תרגול
                </div>
                <div class="column">
                  נושא
                </div>
                <div class="column">
                  סטטוס
                </div>
                <div class="column">
                  הגדרות
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div :class="!exercise.status && 'not-active'" class="row" v-for="(exercise, idx) in exercises" :key="idx">
                <div class="column-item">{{ exercise.id }}</div>
                <div class="column-item flex">
                  <span @click="setExerciseSortOrder(exercise, idx, 1)"><b-icon size="is-small" icon="arrow-down"
                        class="sort__order__button">+</b-icon></span>
                    <span class="sort__order__value">{{ exercise.sortOrder }}</span>
                    <span @click="setExerciseSortOrder(exercise, idx, -1)"><b-icon size="is-small" icon="arrow-up"
                        class="sort__order__button">-</b-icon></span>
                </div>
                <div class="column-item">{{ exercise.name }}</div>
                <div class="column-item">{{ exercise.categoryName  }}</div>
                <div class="column-item">{{ exercise.status ? 'פעיל' : 'לא פעיל' }}</div>
                <div>
                  <b-tooltip type="is-white" label="מעבר לתרגול">
                    <a :href="`/exercise/${exercise.id}`" style="color: #1eb2d0;" class="updateItem">
                      <b-icon pack="fa" icon="pen"> </b-icon>
                    </a>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 3">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <b-button @click="organizeLawsSortOrder" type="is-info">סדר מחדש</b-button>
            <button class="new" @click="newLessonLaw">הוספת מקבץ חקיקה ליחידת הלימוד</button>
          </div>
          <div v-if="!isLoading && laws && laws.length" class="section-container">
            <h1>ניהול מקבצי חקיקה ביחידת הלימוד: {{ lesson.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  סדר
                </div>
                <div class="column">
                  שם
                </div>
                <div class="column">
                  מקבץ
                </div>
                <div class="column">
                  קטגוריה
                </div>
                <div class="column">
                  סטטוס
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div :class="!law.status && 'not-active'" class="row" v-for="(law, idx) in laws" :key="idx">
                <div class="column-item">{{ law.id }}</div>
                <div class="column-item flex">
                  <span @click="setLawSortOrder(law, idx, 1)"><b-icon size="is-small" icon="arrow-down"
                        class="sort__order__button">+</b-icon></span>
                    <span class="sort__order__value">{{ law.sortOrder }}</span>
                    <span @click="setLawSortOrder(law, idx, -1)"><b-icon size="is-small" icon="arrow-up"
                        class="sort__order__button">-</b-icon></span>
                </div>
                <div class="column-item">{{ law.name }}</div>
                <div class="column-item">{{ law.lawCollectionName || '' }}</div>
                <div class="column-item">{{ law.exerciseCategoryName || '' }}</div>
                <div class="column-item">{{ law.status ? 'פעיל' : 'לא פעיל' }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 4">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <b-button @click="organizeVerdictsSortOrder" type="is-info">סדר מחדש</b-button>
            <button class="new" @click="newLessonVerdict">הוספת תקציר פסיקה ליחידת הלימוד</button>
          </div>
          <div v-if="!isLoading && verdicts && verdicts.length" class="section-container">
            <h1>ניהול תקצירי פסיקה ביחידת הלימוד: {{ lesson.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  סדר
                </div>
                <div class="column">
                  שם
                </div>
                <div class="column">
                  תקציר
                </div>
                <div class="column">
                  חוק
                </div>
                <div class="column">
                  קטגוריה
                </div>
                <div class="column">
                  סטטוס
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div :class="!verdict.status && 'not-active'" class="row" v-for="(verdict, idx) in verdicts" :key="idx">
                <div class="column-item">{{ verdict.id }}</div>
                <div class="column-item flex">
                  <span @click="setVerdictSortOrder(verdict, idx, 1)"><b-icon size="is-small" icon="arrow-down"
                        class="sort__order__button">+</b-icon></span>
                    <span class="sort__order__value">{{ verdict.sortOrder }}</span>
                    <span @click="setVerdictSortOrder(verdict, idx, -1)"><b-icon size="is-small" icon="arrow-up"
                        class="sort__order__button">-</b-icon></span>
                </div>
                <div class="column-item">{{ verdict.name }}</div>
                <div class="column-item">{{ verdict.verdictSummaryName || '' }}</div>
                <div class="column-item">{{ verdict.lawName || '' }}</div>
                <div class="column-item">{{ verdict.exerciseCategoryName || '' }}</div>
                <div class="column-item">{{ verdict.status ? 'פעיל' : 'לא פעיל' }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-if="tab === 5">
        <template>
          <div class="Activity__Component__TabsContent__Tasks__Header">
            <!-- <b-button @click="organizeVerdictsSortOrder" type="is-info">סדר מחדש</b-button> -->
            <button class="new" @click="newLessonBook">שיוך ספר ליחידת הלימוד</button>
          </div>
          <div v-if="!isLoading && books && books.length" class="section-container">
            <h1>ניהול ספרים ביחידת הלימוד: {{ lesson.name }} </h1>
            <div class="document-table">
              <div class="columns">
                <div class="column">
                  מזהה
                </div>
                <div class="column">
                  סדר
                </div>
                <div class="column">
                  שם
                </div>
                <div class="column">
                  מחבר
                </div>
                <div class="column">
                  סטטוס
                </div>
                <!-- <div class="column" style="width: 50px"></div> -->
              </div>
              <div class="row" v-for="(book, idx) in books" :key="idx">
                <div class="column-item">{{ book.bookId }}</div>
                <div class="column-item">{{ book.sortOrder }}</div>
                <div class="column-item">{{ book.bookName }}</div>
                <div class="column-item">{{ book.bookAuthor }}</div>
                <div class="column-item">{{ book.status ? 'פעיל' : 'לא פעיל' }}</div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import ActivityItem from "@/components/ActivityItem.vue";
import moment from "moment";
// import { ContentLoader } from "vue-content-loader";
import EntityItemsService from "@/services/EntityItemsService";
import DialogVideoPreview from "@/components/DialogVideoPreview.vue";
// import UserService from "@/services/UserService";
import _ from "lodash";
import { ModalProgrammatic as Modal } from "buefy";
import NewActivity from "./NewActivity.vue";
import DialogAddExercise from "./DialogAddExercise.vue";
import DialogAddLaw from "./DialogAddLaw.vue";
import DialogAddLessonVideo from "./DialogAddLessonVideo.vue";
import LessonService from "../services/LessonService";
import DialogAddVerdict from "./DialogAddVerdict.vue";
import DialogAddLessonBook from "./DialogAddLessonBook.vue";

export default {
  name: "Activity",
  components: {
    // ActivityItem,
    // ContentLoader,
    NewActivity,
  },
  props: {
    activities: Array,
    loading: Boolean,
    entityType: String,
    entityId: String,
  },
  // updated() {
  //   const params = window.location.search.substring(1);
  //   const lastIdx = params.lastIndexOf("=");
  //   const taskId = params.slice(lastIdx + 1);
  //   const task = this.$refs[`taskElement${taskId}`][0];
  //   task.isOpen = true;
  //   this.$nextTick(() => {
  //     task.$el.scrollIntoView({ behavior: "smooth" });
  //   });
  // },
  computed: {
    course() {
      return this.$store.state.auth?.course;
    },
    lesson() {
      return this.$store.state.lesson;
    },
    userName() {
      return `${this.$store.state.user.privateName} ${this.$store.state.user.lastName}`;
    },
    haveNoActivities() {
      return (
        !this.loading &&
        (!this.groupedActivities || !Object.keys(this.groupedActivities).length)
      );
    },
    haveNoNotes() {
      return (
        !this.loading &&
        (!this.groupedActivitiesNotes ||
          !Object.keys(this.groupedActivitiesNotes).length)
      );
    },
    haveNoTasks() {
      return (
        !this.loading &&
        (!this.groupedActivitiesTasks ||
          !Object.keys(this.groupedActivitiesTasks).length)
      );
    },
    groupedActivities() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) => this.deletedItems.indexOf(item.Id) === -1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesNotes() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 1
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
    groupedActivitiesTasks() {
      if (this.activities && this.activities.length) {
        const withoutDeleted = this.activities.filter(
          (item) =>
            this.deletedItems.indexOf(item.Id) === -1 && item.ItemType === 2
        );
        return _.groupBy(withoutDeleted, (item) =>
          moment(item.CreateDate, "YYYY-MM-DDThh:mm").format("MM/YYYY")
        );
      }
      return null;
    },
  },
  data() {
    return {
      tab: 1,
      showNewNote: false,
      showNewTask: false,
      deletedItems: [],
      isLoading: false,
      videos: null,
      exercises: null,
      laws: null,
      verdicts: null,
      books: null
    };
  },
  created() {
    this.loadVideos();
  },
  methods: {
    onDelete(id) {
      this.deletedItems.push(id);
    },
    previewVideo(link) {
      Modal.open({
        component: DialogVideoPreview,
        customClass: "entity-modal",
        props: {
          link: link
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    saveTask(data) {
      this.$refs.taskActivity.setLoading(true);
      EntityItemsService.addTask(this.entityType, this.entityId, data)
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewTask = false;
        })
        .catch(() => {
          this.$refs.taskActivity.setLoading(false);
        })
        .finally(() => { });
    },
    setVideoSortOrder(currVideo, idx, action) {
      const currentVideo = currVideo;
      const videoToChange = this.videos[idx + action];
      if (!videoToChange) return;
      if (action === 1) {
        videoToChange.sortOrder = currentVideo.sortOrder;
        currentVideo.sortOrder += 1;
      } else {
        videoToChange.sortOrder = currentVideo.sortOrder;
        currentVideo.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentVideo.id,
          sortOrder: currentVideo.sortOrder
        },
        itemToChange: {
          id: videoToChange.id,
          sortOrder: videoToChange.sortOrder
        }
      }
      this.isLoading = true;
      LessonService.setLessonVideosSortOrder(model)
        .then(() => {
          this.loadVideos();
          this.isLoading = false;
        });
    },
    organizeVideosSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonVideos(this.videos)
        .then(() => {
          this.loadVideos();
          this.isLoading = false;
        });
    },
    setExerciseSortOrder(currExercise, idx, action) {
      const currentExercise = currExercise;
      const exerciseToChange = this.exercises[idx + action];
      if (!exerciseToChange) return;
      if (action === 1) {
        exerciseToChange.sortOrder = currentExercise.sortOrder;
        currentExercise.sortOrder += 1;
      } else {
        exerciseToChange.sortOrder = currentExercise.sortOrder;
        currentExercise.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentExercise.id,
          sortOrder: currentExercise.sortOrder
        },
        itemToChange: {
          id: exerciseToChange.id,
          sortOrder: exerciseToChange.sortOrder
        }
      }
      this.isLoading = true;
      LessonService.setLessonExercisesSortOrder(model)
        .then(() => {
          this.loadExercises();
          this.isLoading = false;
        });
    },
    organizeExercisesSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonExercises(this.exercises)
        .then(() => {
          this.loadExercises();
          this.isLoading = false;
        }).catch(() => {
          Toast.open({
            type: "is-danger",
            message: "לא היה ניתן לבצע את הפעולה.",
            duration: 8000,
          });
          this.isLoading = false;
        })
    },
    setLawSortOrder(currLaw, idx, action) {
      const currentLaw = currLaw;
      const lawToChange = this.laws[idx + action];
      if (!lawToChange) return;
      if (action === 1) {
        lawToChange.sortOrder = currentLaw.sortOrder;
        currentLaw.sortOrder += 1;
      } else {
        lawToChange.sortOrder = currentLaw.sortOrder;
        currentLaw.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentLaw.id,
          sortOrder: currentLaw.sortOrder,
          lawCollectionId: currentLaw.lawCollectionId,
          exerciseCategoryId: currentLaw.exerciseCategoryId,
          lessonId: currentLaw.lessonId
        },
        itemToChange: {
          id: lawToChange.id,
          sortOrder: lawToChange.sortOrder,
          lawCollectionId: lawToChange.lawCollectionId,
          exerciseCategoryId: lawToChange.exerciseCategoryId,
          lessonId: lawToChange.lessonId
        }
      }
      this.isLoading = true;
      LessonService.setLessonLawsCollectionSortOrder(model)
        .then(() => {
          this.loadLaws();
          this.isLoading = false;
        });
    },
    organizeLawsSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonLawsCollection(this.laws)
        .then(() => {
          this.loadLaws();
          this.isLoading = false;
        });
    },
    setVerdictSortOrder(currVerdict, idx, action) {
      const currentVerdict = currVerdict;
      const verdictToChange = this.verdicts[idx + action];
      if (!verdictToChange) return;
      if (action === 1) {
        verdictToChange.sortOrder = currentVerdict.sortOrder;
        currentVerdict.sortOrder += 1;
      } else {
        verdictToChange.sortOrder = currentVerdict.sortOrder;
        currentVerdict.sortOrder -= 1;
      }
      const model = {
        currentItem: {
          id: currentVerdict.id,
          sortOrder: currentVerdict.sortOrder,
          verdictSummaryId: currentVerdict.verdictSummaryId,
          lessonId: currentVerdict.lessonId
        },
        itemToChange: {
          id: verdictToChange.id,
          sortOrder: verdictToChange.sortOrder,
          verdictSummaryId: verdictToChange.verdictSummaryId,
          lessonId: verdictToChange.lessonId
        }
      }
      this.isLoading = true;
      LessonService.setLessonVerdictsCollectionSortOrder(model)
        .then(() => {
          this.loadVerdicts();
          this.isLoading = false;
        });
    },
    organizeVerdictsSortOrder() {
      this.isLoading = true;
      LessonService.OrganizeLessonVerdictsCollection(this.verdicts)
        .then(() => {
          this.loadVerdicts();
          this.isLoading = false;
        });
    },
    saveNote(data) {
      this.$refs.noteActivity.setLoading(true);
      EntityItemsService.addNote(this.entityType, this.entityId, {
        Body: data.Body,
      })
        .then((r) => {
          this.activities.unshift(r.data);
          this.showNewNote = false;
        })
        .finally(() => {
          this.$refs.noteActivity.setLoading(false);
        });
    },
    close() {
      this.showNewNote = false;
    },
    closeTask() {
      this.showNewTask = false;
    },
    newNote() {
      this.showNewNote = true;
    },
    newTask() {
      this.showNewTask = true;
    },
    goToExercise(id) {
      this.$router.replace(`exercise/${id}`)
    },
    newLessonExercise() {
      Modal.open({
        component: DialogAddExercise,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: { Value: this.entityId, Text: this.lesson.name }
        },
        events: {
          customEvent: () => {
            this.loadExercises();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonLaw() {
      Modal.open({
        component: DialogAddLaw,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: { Value: this.entityId, Text: this.lesson.name }
        },
        events: {
          customEvent: () => {
            this.loadLaws();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonVerdict() {
      Modal.open({
        component: DialogAddVerdict,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: { Value: this.entityId, Text: this.lesson.name }
        },
        events: {
          customEvent: () => {
            this.loadVerdicts();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonBook() {
      Modal.open({
        component: DialogAddLessonBook,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: { Value: this.entityId, Text: this.lesson.name }
        },
        events: {
          customEvent: () => {
            this.loadBooks();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    newLessonVideo() {
      Modal.open({
        component: DialogAddLessonVideo,
        customClass: "entity-modal",
        props: {
          store: this.$store,
          lessonId: { Value: this.entityId, Text: this.lesson.name }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    loadVideos() {
      this.isLoading = true;
      LessonService.getAdminVideos(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId],
        PageSize: 999
      }).then((r) => {
        this.videos = r.data.items;
      })
        .finally(() => {
          this.tab = 1;
          this.isLoading = false;
        });
    },
    loadExercises() {
      this.isLoading = true;
      LessonService.getAdminExercises(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId],
        PageSize: 999
      })
        .then((r) => {
          this.exercises = r.data.items;
        })
        .finally(() => {
          this.tab = 2;
          this.isLoading = false;
        });
    },
    loadLaws() {
      this.isLoading = true;
      LessonService.getAdminLaws(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.laws = r.data.items;
        })
        .finally(() => {
          this.tab = 3;
          this.isLoading = false;
        });
    },
    loadVerdicts() {
      this.isLoading = true;
      LessonService.getAdminVerdicts(this.$store.state.auth.course?.id, {
        LessonIds: [this.entityId]
      })
        .then((r) => {
          this.verdicts = r.data.items;
        })
        .finally(() => {
          this.tab = 4;
          this.isLoading = false;
        });
    },
    loadBooks() {
      this.isLoading = true;
      LessonService.getAdminBooks(this.course.id, {
        LessonIds: [this.entityId],
        PageNumber: 1,
        PageSize: 999
      })
        .then((r) => {
          this.books = r.data.items;
        })
        .finally(() => {
          this.tab = 5;
          this.isLoading = false;
        });
    },
    parsedGroupedDate(date) {
      if (date) {
        const localLocale = moment(date, "MM/YYYY");
        localLocale.locale("he");
        return localLocale.format("MMMM YYYY");
      }
      return null;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style scoped lang="scss">
.Activity__Component__Tabs {
  display: flex;
  border-bottom: 1px solid #cbd6e2;
  margin: 0px 20px;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eef1f3;
  padding-top: 6px;

  .Activity__Component__Tabs__Item {
    padding: 15px 25px;
    position: relative;
    cursor: pointer;
    font-size: 15px;

    &:not(.active):hover {
      color: #4188b7;
    }

    &.active:after {
      content: "";
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -3px;
      background-color: #2e3f50;
      height: 5px;
      right: 0;
      border-radius: 3px;
    }
  }
}

.flex {
  display: flex !important;
}

.section-container {
  display: flex;
  flex-direction: column;
  margin: 0 2% 50px 3%;

  // overflow-x: hidden;
  // align-items: center;
  &:last-child {
    // margin: 0 5% 0 5%;
  }

  img {
    height: 15px;
    margin: 0 2px;
  }

  h1 {
    // margin: 0 2%;
    // width: 15%;
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }

  p {
    padding: 0px 1%;
    width: 83%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    word-break: break-word;

    ::v-deep .title {
      display: none;
    }
  }

  .workplan {
    display: flex;
    flex-direction: column;

    span {
      // display: flex;
      // align-items: center;
      width: fit-content;
    }

  }
}

.document-table-title-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  // margin-bottom: 25px;
  .document-table-title {
    color: #1eb2d0;
    font-size: 17px;
    font-weight: 700;
  }

  .label-upload-file {
    margin-left: 20px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #57b7ca;
    cursor: pointer;
  }
}

.document-table {
  display: table;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 70px;
  border: 1px solid #d2d2d2;
  background-color: #fff;
  padding: 5px;

  .columns {
    font-weight: bold;
  }

  .columns,
  .row {
    display: table-row;
    border-bottom: 1px solid #d2d2d2;
    margin: 0;

    .removeItem,
    .updateItem {
      text-decoration: underline;
    }

    &>div {
      display: table-cell;
      padding: 10px 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    .Table_Field {
      min-width: 170px;
    }
  }
  .not-active {
    background-color: whitesmoke;
  }
}

.Activity__Component__TabsContent {
  padding: 20px 30px;

  & .Activity__Item {
    margin: 8px 0;
  }
}

.loading {
  max-height: 300px;
  width: 600px;
  margin: 0 auto;
}

.no-activities {
  font-size: 25px;
  text-align: center;
  opacity: 0.8;

  span {
    margin-top: 50px;

    ::v-deep .mdi-timeline-text-outline {
      &::before {
        font-size: 50px;
      }
    }
  }
}

.sort__order__button {
  padding: 5px;
  background-color: #1eb2d0;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  height: 17px;
  width: 17px;
}

.sort__order__value {
  width: 15px;
  margin: 0 10px;
  display: inline-block;
  text-align: center;
}

button.new {
  background-color: #3b5671;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 8px 18px;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: #4d6782;
  }
}

.Activity__Component__TabsContent__Tasks__Header {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.Activity__Component__TabsContent__Tasks__Content {
  h1 {
    text-align: center;
  }
}
</style>
