<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div class="edit-section">
      <a @click="editStudentCourses">ערוך</a>
    </div>
    <div v-if="studentCoursesModel && studentCoursesModel.length" class="StudentGroups__Container">
      <div :class="[course.isSelected && 'green']" class="StudentGroupsWidget__Component"
        v-for="course in studentCoursesModel" :key="course.id">
        <p>{{ course.courseName }}</p>
      </div>
    </div>
    <div v-if="!loading && !studentCoursesModel.length">אין קורסים משויכים למשתמש</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import { ModalProgrammatic as Modal } from "buefy";
import DialogUpdateStudentCourses from "../DialogUpdateStudentCourses.vue";
import DialogUpdateProfile from "../DialogUpdateProfile.vue";
import UserService from "../../services/UserService";
// import LeasingCompaniesService from "../../services/LeasingCompaniesService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch"],
  components: {
    ClipLoader,
  },
  data() {
    return {
      studentCoursesModel: null,
      loading: false
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id", "privateName", "lastName"]),
    };
  },
  created() {
    // if (this.id) {
    //   UserService.getUsercourses(this.id)
    //     .then((res) => {
    //       this.studentCoursesModel = res.data;
    //     });
    // }
  },
  computed: {
  },
  methods: {
    displayDate() {
      return moment(this.CreateDate).format("DD/MM/YYYY");
    },
    editStudentCourses() {
      Modal.open({
        component: DialogUpdateStudentCourses,
        customClass: "entity-modal",
        props: {
          userId: this.id,
          privateName: this.privateName,
          lastName: this.lastName,
        },
        events: {
          customEvent: () => {
            this.init();
          }
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    openModal() {
      Modal.open({
        component: DialogUpdateProfile,
        customClass: "entity-modal",
        props: {
          profileId: this.clientId,
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    init() {
      if (this.id) {
        this.loading = true;
        UserService.getUserCourses(this.id)
          .then((res) => {
            this.studentCoursesModel = res.data.filter((c) => c.isSelected);
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.studentCoursesModel?.length });
            this.loading = false;
          });
        this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StudentGroups__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.StudentGroupsWidget__Component {
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.edit-section {
  padding: 0 0 10px 10px;
  display: flex;
  flex-direction: row-reverse;

  a:hover {
    text-decoration: underline;
  }
}

.green {
  background-color: #dff0d8;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
