var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogAddLaw__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("עדכון שאלת חשיפה")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _vm.model
        ? _c(
            "section",
            { staticClass: "modal-card-body" },
            [
              _c("FieldInlineText", {
                attrs: { label: "תווית יחידה" },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.unitDisplayName,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "unitDisplayName", $$v)
                  },
                  expression: "model.unitDisplayName"
                }
              }),
              _c(
                "b-field",
                { attrs: { label: "שאלה" } },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor, config: _vm.editorConfig },
                    on: { input: _vm.onChange },
                    model: {
                      value: _vm.model.question,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "question", $$v)
                      },
                      expression: "model.question"
                    }
                  })
                ],
                1
              ),
              _c("FieldInlineSelect", {
                attrs: {
                  label: "תשובה",
                  "null-is-value": true,
                  filterable: false,
                  clearable: false,
                  options: [
                    { Value: true, Text: "נכון" },
                    { Value: "0", Text: "לא נכון" }
                  ]
                },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.answer,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "answer", $$v)
                  },
                  expression: "model.answer"
                }
              }),
              _c("FieldInlineText", {
                attrs: { label: "פתרון" },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.detailedAnswer,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "detailedAnswer", $$v)
                  },
                  expression: "model.detailedAnswer"
                }
              }),
              _c("FieldInlineText", {
                attrs: { label: "רמת קושי", type: "number" },
                on: { onChange: _vm.onChange },
                model: {
                  value: _vm.model.level,
                  callback: function($$v) {
                    _vm.$set(_vm.model, "level", $$v)
                  },
                  expression: "model.level"
                }
              }),
              _c(
                "div",
                { staticClass: "checkbox-container" },
                [
                  _c(
                    "b-switch",
                    {
                      staticClass: "ltr",
                      on: { input: _vm.onChange },
                      model: {
                        value: _vm.model.status,
                        callback: function($$v) {
                          _vm.$set(_vm.model, "status", $$v)
                        },
                        expression: "model.status"
                      }
                    },
                    [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }