<template>
  <div class="" v-if="ready">
    <span v-if="loading" class="loading">
      <ClipLoader color="#2e3f50" :loading="loading" :size="20" />
    </span>
    <div style="display: flex;align-items: center;">
      <FieldInlineTextArea style="flex: 1;" label="כתיבת תגובה פנימית" v-model="newComment" />
      <b-button @click="sendNewComment">שלח</b-button>
    </div>
    <div v-if="requestHistoryModel && requestHistoryModel.length" class="StudentGroups__Container">
      <div class="StudentGroupsWidget__Component"
        v-for="history in requestHistoryModel" :key="history.id">
        <p><span style="font-weight: bold;">בתאריך {{ parseDate(history.createDate) }}</span>: {{ history.byUser }} {{ history.text }}</p>
      </div>
    </div>
    <div v-if="!loading && !requestHistoryModel.length">אין היסטוריית טיפול לפנייה</div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ClipLoader } from "@saeris/vue-spinners";
import moment from "moment";
import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
import { ModalProgrammatic as Modal, ToastProgrammatic as Toast } from "buefy";
import DialogUpdateProfile from "../DialogUpdateProfile.vue";
import CoursesService from "../../services/CoursesService";
// import LeasingCompaniesService from "../../services/LeasingCompaniesService";

export default {
  name: "ClientWidget",
  props: ["ready", "namespace", "fetch"],
  components: {
    ClipLoader,
    FieldInlineTextArea
  },
  data() {
    return {
      requestHistoryModel: null,
      loading: false,
      newComment: null
    };
  },
  beforeCreate() {
    const { namespace } = this.$options.propsData;
    const { mapState } = createNamespacedHelpers(namespace);
    this.$options.computed = {
      ...mapState(["id", "privateName", "lastName"]),
    };
  },
  created() {
    // if (this.id) {
    //   CoursesService.getUsertracks(this.id)
    //     .then((res) => {
    //       this.requestHistoryModel = res.data;
    //     });
    // }
  },
  computed: {
    courseId() {
      return this.$store.state.auth.course.id;
    },
  },
  methods: {
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    sendNewComment() {
      if (this.newComment) {
        CoursesService.updateRequestHistory(this.id, this.$store.state.auth.course.id, {
          Text: this.newComment
        }).then(() => {
          this.newComment = null;
          this.init();
          Toast.open({
            type: "is-success",
            message: "התגובה התווספה בהצלחה!",
            duration: 8000,
          });
        })
      }
    },
    openModal() {
      Modal.open({
        component: DialogUpdateProfile,
        customClass: "entity-modal",
        props: {
          profileId: this.clientId,
        },
        canCancel: false,
        trapFocus: true,
      });
    },
    init() {
      if (this.id) {
        this.loading = true;
        CoursesService.getRequestLogs(this.id, this.$store.state.auth.course.id)
          .then((res) => {
            this.requestHistoryModel = res.data.content.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));
          }).finally(() => {
            this.$emit('onReady', { titleCount: this.requestHistoryModel?.length });
            this.loading = false;
          });
        this.$emit("onReady", true);
      }
    },
  },
  watch: {
    ready(newValue) {
      if (newValue) {
        this.init();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.StudentGroups__Container {
  max-height: 400px;
  padding: 0 10px;
  overflow: auto;
}

.edit-section {
  padding: 0 0 10px 10px;
  display: flex;
  flex-direction: row-reverse;
  a:hover {
    text-decoration: underline;
  }
}

.StudentGroupsWidget__Component {
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  padding: 10px;
  border: 1px solid #e4eaf1;
  background-color: #eef1f3;
  border-radius: 3px;
  position: relative;
}

.green {
  background-color: #dff0d8;
}

.preview {
  position: absolute;
  left: 10px;
  top: 10px;
  color: #57b7ca;
  cursor: pointer;
  font-weight: 500 !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>
